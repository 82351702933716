import BasePage from 'pages/BasePage';
import GamificationCoinsAndStreaks from 'app/container/GamificationCoinsAndStreaks';

const GamificationCoinsRewards = () => {
  return (
    <>
      <BasePage>
        <GamificationCoinsAndStreaks/>
      </BasePage>
    </>
  );
};

export default GamificationCoinsRewards;
