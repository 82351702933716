import BasePage from 'pages/BasePage';

import GamificationActivityContainer from 'app/container/GamificationActivity';

const GamificationActivity = () => {
  return (
    <>
      <BasePage>
        <GamificationActivityContainer/>
      </BasePage>
    </>
  );
};

export default GamificationActivity;
