import { API_CONSTANTS } from "app/constants/api-contants";
import * as dataAccess from "utils/ajax";
import Parsers from "utils/Parsers";
import Toast from "app/utils/Toast";
import ApiParser from "utils/ApiParser";

export const getGamificatinActivities = async () => {
  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_GAMIFICATION_ACTIVITES
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {

    return responseObj.data;
  } else {
    return [];
  }
};

export const getGamificatinActivityById = async (id) => {
  let responseObj = await dataAccess.get(`${API_CONSTANTS.GET_GAMIFICATION_ACTIVITY_BY_ID}/${id}`);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    console.log(responseObj);

    return responseObj.data;
  } else {
    return [];
  }
};
export const updateGamificatinActivityById = async (id,payload) => {
    try {
        const data = {
            data: {
              id: payload.id,
              name: payload.name,
              coins: Number(payload.coins),
              frequency: payload.frequency,
              daily_capping: Number(payload.daily_capping),
              icon: payload.icon
            }
          };
        return await dataAccess.put(`${API_CONSTANTS.UPDATE_GAMIFICATION_ACTIVITY}`, data);
      } catch (error) {
        console.error('Error in updateGamificatinActivityById:', error);
        throw error;
      }
  };
  export const getGamificationToggle = async (id, status) => {
    try {
      const data = {
        id: id,
        status: status // Already boolean from component
      };
      
      return await dataAccess.post(API_CONSTANTS.TOGGLE_GAMIFICATION_ACTIVITY, { data });
    } catch (error) {
      console.error('Error in updateGamificatinActivityById:', error);
      throw error;
    }
  };