import { useHistory } from "react-router";
// import '../index.scss';
import moment from "moment";
import Toggle from "react-toggle";

function CustomTable({
  data,
  searchQuery = "",
  tableHeader,
  handleToggleChange,
}) {
  
  const history = useHistory();
  const onToggleChange = (id, currentStatus) => {
     // Convert the current status to boolean for API payload
     const isCurrentlyActive = currentStatus === "ACTIVE";
     // We want to send the NEW status (opposite of current) to the API
     handleToggleChange(id, !isCurrentlyActive);
  };
  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className="nowrap text-center">{item.Header}</th>;
        })}
      </tr>
      {data.map((items, indx) => (
        <tr key={indx}>
          <td>{items.activity_name}</td>

          <td>{items.coins}</td>
          <td>{items.frequency}</td>
          <td>{items.daily_capping}</td>
          <td>
            <div className="d-flex justify-content-around align-items-center">
              <button
                type="button"
                onClick={() => {
                  history.push({
                    pathname: `/gamification-activity/edit/${items.id}`,
                    // state: {
                    //   ...row.original,
                    // },
                  });
                }}
                className="btn btn-outline-primary"
              >
                Edit
              </button>

              <div>
                <Toggle
                  icons={false}
                  defaultChecked={items.status === "ACTIVE"}
                  onChange={() => onToggleChange(items.id, items.status)}
                  className="react-toggle"
                />
              </div>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan="10000">No data found</td>{" "}
        </tr>
      )}
      {/* {data.length === 0 ? (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )
      :
      <tr>
        <td colspan={5} style={{'textAlign':'right'}}><h5><b>Estimated Total</b></h5></td>
        <td>100</td>
      </tr>
    
    } */}
    </table>
  );
}

export default CustomTable;
