import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import CustomTable from "./table";
import Search from "app/components/search";
import Pagination from "app/components/NewPagination/pagination";
import { GamificationActivityHeader } from "app/constants/table-header-constants";
import { getGamificatinActivities, getGamificationToggle } from "app/api/gamiticationactivities";
import Toast from "app/utils/Toast";

const GamificationActivityContainer = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch activities on component mount
  useEffect(() => {
    getActivitesDataHandler();
  }, []);

  // Fetch activities data
  async function getActivitesDataHandler() {
    try {
      const dataArr = await getGamificatinActivities();
      setData(dataArr);
    } catch (error) {
      Toast.error('Failed to fetch activities');
      console.error('Error fetching activities:', error);
    }
  }

  // Filter data based on search query
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    
    return data.filter((item) => 
      item.activity_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  // Paginate filtered data
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input
  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle toggle change
  const handleToggleChange = async (id, newStatus) => {
    try {
      const response = await getGamificationToggle(id, newStatus);
     
      if (response?.response?.code === 200) {
        Toast.success(response?.data?.message);
        // await getActivitesDataHandler();
      } else {
        Toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      Toast.error('Failed to update status');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Earn Coins</h3>
            <Search
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search Activity"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="content-container__table">
            <CustomTable
            searchQuery={searchQuery}
              data={paginatedData}
              tableHeader={GamificationActivityHeader}
              handleToggleChange={handleToggleChange}
            />
          </div>
        </div>
      </div>

      {filteredData.length > itemsPerPage && (
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-center">
            <Pagination 
              pages={totalPages} 
              onChange={handlePageChange} 
              current={currentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GamificationActivityContainer;