import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Toggle from "react-toggle";

function CustomTable({
  data,
  tableHeader,
  searchQuery = "",
  handleDelete,
  handleToggleChange,
}) {
  const history = useHistory();

  const onToggleChange = (item) => {
    const newStatus = item.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    handleToggleChange(item.reward_id, newStatus);
  };

  return (
    <table>
      <thead>
        <tr>
          {tableHeader.map((item, i) => (
            <th key={i} className="nowrap text-center">{item.Header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((items, indx) => (
            <tr key={indx}>
              <td>{items.reward_title}</td>
              <td>{items.category_type}</td>
              <td>{items.offer_type}</td>
              <td>{items.streak_type}</td>
              <td>{items.milestone}</td>
              <td className="d-flex justify-content-around align-items-center">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    type="button"
                    onClick={() => {
                      history.push({
                        pathname: `/gamification-streaks/edit/${items.reward_id}`,
                      });
                    }}
                    className="btn btn-outline-primary"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleDelete(items.reward_id);
                    }}
                    className="btn btn-outline-primary"
                  >
                    Delete
                  </button>
                </div>
                <div>
                  <Toggle
                    icons={false}
                    defaultChecked={items.status === "ACTIVE"}
                    onChange={() => onToggleChange(items)}
                    className="react-toggle"
                  />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={tableHeader.length}>
              {searchQuery 
                ? "No results found matching your search" 
                : "No data available"
              }
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default CustomTable;