import SlidingToggle from "app/components/SlidingToggle";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import "./index.scss";
import Coins from "./Coins";
import Streaks from "./Streaks";
import Button from "app/components/button";
import Search from "app/components/search";
import { deleteRewardByid, getGamificatinRewards, getGamificationRewardToggle } from "app/api/gamificationReward";
import Toast from "app/utils/Toast";
import Loader from 'components/Loader';
const GamificationCoinsAndStreaks = () => {
  const history = useHistory();
  const location = useLocation();
  
  // Parse URL parameters
  const queryParams = new URLSearchParams(location.search);
  const typeFromUrl = queryParams.get('redemption_type')?.toUpperCase();
  
  const [rewardSelection, setrewardSelection] = useState(typeFromUrl === 'STREAK');
  const [searchQuery, setSearchQuery] = useState("");
  const [rewardsData, setRewardsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateUrlParams = (isStreak) => {
    const newType = isStreak ? 'STREAK' : 'COIN';
    const newUrl = `${location.pathname}?redemption_type=${newType}`;
    history.push(newUrl);
  };

  const handleCheckboxChange = () => {
    const newSelection = !rewardSelection;
    setrewardSelection(newSelection);
    setSearchQuery("");
    getAllRewardsData(newSelection);
    updateUrlParams(newSelection);
  };

  const handleAddReward = () => {
    if (!rewardSelection) {
      history.push({
        pathname: `/create-coins`,
      });
    } else {
      history.push({
        pathname: `/create-streaks`,
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const getAllRewardsData = async (isStreak = rewardSelection) => {
    try {
      setIsLoading(true);
      const redemptionType = isStreak ? 'STREAK' : 'COIN';
      const response = await getGamificatinRewards({ redemption_type: redemptionType });
      setRewardsData(response || []);
    } catch (error) {
      console.error('Error fetching rewards:', error);
      setRewardsData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteRewardByid(id);
    if (res.response.code === 200) {
      Toast.success(res.data.data);
      getAllRewardsData(); // Refresh the data after deletion
    } else {
      Toast.error(res.data.data);
    }
  };

  // Effect to handle initial URL params and data loading
  useEffect(() => {
    const type = queryParams.get('redemption_type');
    if (!type) {
      // If no type parameter exists, set default to 'coin'
      updateUrlParams(false);
    }
    getAllRewardsData(type === 'STREAK');
  }, []);

  // Effect to sync URL changes
  useEffect(() => {
    const type = queryParams.get('redemption_type');
    const shouldBeStreak = type === 'STREAK';
    if (rewardSelection !== shouldBeStreak) {
      setrewardSelection(shouldBeStreak);
      getAllRewardsData(shouldBeStreak);
    }
  }, [location.search]);

  const handleToggleChange = async (id, newStatus) => {
    try {
      const response = await getGamificationRewardToggle(id, newStatus);
      if (response.response.success || response.response.code === 200) {
        setRewardsData(prevData => 
          prevData.map(item => 
            item.reward_id === id 
              ? { ...item, status: newStatus } 
              : item
          )
        );
        Toast.success("Status Updated Successfully");
      } else {
        Toast.error("Error Updating the status");
      }
    } catch (error) {
      console.error('Error updating status:', error);
      Toast.error('Failed to update status');
    }
  };
  if (isLoading === true) {
    return <Loader />;
  }
  return (
    <>
     <div className="" style={{ width: "100%" }}>
        <div className="blogsForm_content" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <h1>Rewards</h1>
            <div>
              <Button name="Add Reward" onClick={handleAddReward} />
            </div>
          </div>
          <div className="d-flex justify-content-between flex-row-reverse align-items-center">
            <Search
              value={searchQuery}
              onChange={handleSearch}
              placeholder={`Search ${
                rewardSelection ? "streaks" : "coins"
              }...`}
              className=""
            />
            <SlidingToggle
              selectedSlide="Coins"
              secondarySlide="Streaks"
              slideCheck={rewardSelection}
              handleSlideChange={handleCheckboxChange}
            />
          </div>
          {rewardSelection ? (
            <Streaks 
              searchQuery={searchQuery} 
              data={rewardsData} 
              handleDelete={handleDelete}
              handleToggleChange={handleToggleChange}
            />
          ) : (
            <Coins 
              searchQuery={searchQuery} 
              data={rewardsData} 
              handleDelete={handleDelete}
              handleToggleChange={handleToggleChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GamificationCoinsAndStreaks;