import React, { useState, useMemo, useEffect } from "react";
import CustomTable from "./table";
import { GamificationCoinsHeader } from "app/constants/table-header-constants";
import Pagination from "app/components/NewPagination/pagination";

const Coins = ({ 
  searchQuery, 
  data, 
  handleDelete, 
  handleToggleChange 
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Memoized filtered data
  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      console.warn('Data is not an array:', data);
      return [];
    }
    if (!searchQuery) return data;
    
    return data.filter((item) => 
      item.reward_title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.category_type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.offer_type?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  // Calculate total pages based on filtered data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Reset to first page when search query changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // Paginate filtered data
  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage]);

  const paginationHandler = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="content-container__table">
          <CustomTable
            data={currentData}
            tableHeader={GamificationCoinsHeader}
            searchQuery={searchQuery}
            handleDelete={handleDelete}
            handleToggleChange={handleToggleChange}
          />
        </div>
      </div>
      {filteredData.length > itemsPerPage && (
        <div className="subcategory-container__pagination">
          <Pagination 
            pages={totalPages} 
            onChange={paginationHandler} 
            current={currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default Coins;