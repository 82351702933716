export const APP_CONSTANTS = {
  LOGIN: '/',
  VIDEO: '/video',
  NEWSFEED: '/feed',
  CREATE_FEED: '/feed/create',
  EDIT_FEED: '/feed/edit/:id',
  BANNER_LIST: '/banner',
  WIDGET_LIST: '/widget',
  BANNER_LARGE_LIST: '/large',
  BANNER_SMALL_LIST: '/small',
  DIAGNOSTICS_BANNER_LARGE_LIST: '/diagnosticsLarge',
  DIAGNOSTICS_BANNER_SMALL_LIST: '/diagnosticsSmall',
  GAMIFICATION: '/challenges',
  ONBOARDING: '/onboarding',
  CREATE_GAMIFICATION: '/challenges/create',
  EDIT_GAMIFICATION: '/challenges/edit/:id',
  USERS: '/users',
  CREATE_USER: '/user/create',
  EDIT_USER: '/user/edit/:id',
  BREATEFREE_CREATE: '/breathe-free-create',
  EDIT_BREATHEFREE: '/breathe-free/:id',
  COURSES_RECOMMEND: '/courses/recommend',
  COURSES_PAGE_ORDERING: '/courses/pageOrdering',
  COURSES_BANNERS: '/courses/banners',
  COURSES_CREATION: '/courses/creation',
  COURSES_CREATE: '/courses/create',
  COURSES_EDIT: '/courses/create/edit/:id',
  COURSES_VIDEO: '/courses/video',
  EDIT_COURSES_VIDEO: '/courses/video/edit/:id',
  COURSES_TEXT: '/courses/text',
  EDIT_COURSES_TEXT: '/courses/text/edit/:id',
  COURSES_QUIZ: '/courses/quiz',
  EDIT_COURSES_QUIZ: '/courses/quiz/edit/:id',
  ELS_ADS_SPACE: '/courses/elsAdsSpace',

  CMS_PANEL: '/cms/content',
  CMS_PANEL_CREATE: '/cms/content/create',
  CMS_PANEL_COLLECTIONS: '/cms/collections',
  CMS_PANEL_CREATE_COLLECTION: '/cms/collection/create',
  CMS_PANEL_EDIT_COLLECTION: '/cms/collection/edit/:id',

  CMS_PANEL_TAGS: '/cms/tags/',
  CMS_PANEL_CREATE_TAGS: '/cms/tags/create',
  CMS_PANEL_CREATE_STORIES: '/cms/stories/create',
  CMS_PANEL_EDIT_STORIES: '/cms/stories/edit/:id',
  CMS_PANEL_CREATE_GROUP: '/cms/group/create',
  CMS_PANEL_EDIT_GROUP: '/cms/group/edit/:id',
  CMS_PANEL_BULK_UPLOAD: '/cms/bulk-summary',
  CMS_PANEL_DISCOVER: '/cms/discover',
  CMS_PANEL_DISCOVER_HOME_WIDGET: '/cms/discoverhomewidget',

  CELEBRITY_CREATE: '/celebrity-create',
  CELEBRITY: '/celebrity-video',
  EDIT_CELEBRITY: '/celebrity/:id',

  HOMEPAGEORDER: '/homepage-order',
  HOMEPAGEORDERING: '/homepage-ordering',
  EDIT_HOMEPAGEORDER: '/edit-homepage-order/:id',
  CREATE_HOME_PAGE_ORDER: '/homepage-ordering/create',
  EDIT_HOME_PAGE_ORDER: '/homepage-ordering/edit/:id',
  USERLANDING: '/user-landing',
  CREATE_USER_LANDING: '/user-landing/create-user-landing',
  EDIT_USER_LANDING: '/user-landing/edit/:id',

  LANDING_PAGE: '/landing-page',
  CREATE_LANDING_PAGE: '/landing-page/create',
  EDIT_LANDING_PAGE: '/landing-page/edit/:id',

  MEDICINEPAGEORDER: '/medicine-order',
  EDIT_MEDICINEPAGEORDER: '/edit-medicine-order/:id',
  MEDICINESMALLBANNER: '/medicine-small-banner',
  MEDICINELARGEBANNER: '/medicine-large-banner',
  MEDICINECATEGORIES: '/medicine-categories',
  SELFSUBCATEGORIES: '/self-sub-categories',
  MEDPAYSUBCATEGORIES: '/medpay-sub-categories',
  MEDICINESEARCHSCREEN: '/medicine-search-screen',
  MEDICINESREMINDERSCREEN: '/medicine-reminder-screen',
  MEDICINESDISCOUNT: '/medicine-discount',

  DIAGONOSTICPAGEORDER: '/diagonostic-order',
  EDIT_DIAGONOSTICPAGEORDER: '/edit-diagonostic-order/:id',
  DIAGONOSTICSMALLBANNER: '/diagonostic-small-banner',
  DIAGONOSTICLARGEBANNER: '/diagonostic-large-banner',
  DIAGONOSTICCATEGORIES: '/diagonostic-categories',
  // DIAGONOSTICSELFSUBCATEGORIES: '/self-sub-categories',
  // DIAGONOSTICMEDPAYSUBCATEGORIES: '/medpay-sub-categories',
  DIAGONOSTICSEARCHSCREEN: '/diagonostic-search-screen',

  ACTIVITY_VITALS: '/activity-vitals',
  EDIT_ACTIVITY_VITALS: '/edit-activity-vitals/:id',

  WEBINAR: '/webinar',
  CREATE_WEBINAR: '/webinar/create',
  EDIT_WEBINAR: '/webinar/edit/:id',

  UNDERSTANDING_DEVICES_CREATE: '/understanding_devices-create',
  UNDERSTANDING_DEVICES: '/understanding_devices',
  EDIT_UNDERSTANDING_DEVICES: '/understanding_devices/:id',

  DIDYOUKNOW_CREATE: '/didyouknow-create',
  DIDYOUKNOW: '/didyouknow-video',
  EDIT_DIDYOUKNOW: '/didyouknow/:id',

  SPLASH_SCREEN_CREATE: '/splash-screen-create',
  SPLASH_SCREEN_EDIT: '/splash-screen/:id',
  UPLOAD_MASTER_DATA: '/upload-master-data',
  STATIC_PAGE: '/static-content',
  CREATE_STATIC: '/static-content/create',
  EDIT_STATIC: '/static-content/edit/:id',
  FAQ: '/faq',
  CREATE_FAQ: '/faq/create',
  CREATE_BANNER: '/banner/create',
  EDIT_BANNER: '/banner/edit/:id',
  EDIT_WIDGET: '/widget/edit/:id',

  HABITS: '/habit',
  EDIT_HABITS: '/habit/edit/:id',

  EDIT_FAQ: '/faq/edit/:id',
  PAGE_NOT_FOUND: '/page-not-found',
  ARTICLE_DETAIL: '/article/detail/:id',
  ACTIVITIES: '/activities',
  EDIT_ACTIVITIES: '/activities/edit/:id',
  CREATE_PASSWORD: '/password/create',
  RESET_PASSWORD: '/password/reset',
  FORGOT_PASSWORD: '/password/forgot',
  QUIZ: '/quiz',
  CREATE_QUIZ: '/quiz/create',
  EDIT_QUIZ: '/quiz/edit/:id',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  BREATEFREE: '/educator-video',
  USER_JOURNEY: '/userjourney',
  CREATE_USER_JOURNEY_TASK: '/userjourney/create',
  EDIT_USER_JOURNEY_TASK: '/userjourney/edit/:id',
  USER_JOURNEY_STREAK_REWARDS: '/userjourney/streak-rewards',
  CREATE_USER_JOURNEY_STREAK_REWARD: '/userjourney/streak-reward/create',
  EDIT_USER_JOURNEY_STREAK_REWARD: '/userjourney/streak-reward/edit/:id',

  USER_JOURNEY_COUPON: '/userjourney/coupons',
  CREATE_USER_JOURNEY_COUPON: '/userjourney/coupon/create',
  EDIT_USER_JOURNEY_COUPON: '/userjourney/coupon/edit/:id',
  USER_JOURNEY_MILESTONE: '/userjourney/milestones',
  CREATE_USER_JOURNEY_MILESTONE: '/userjourney/milestone/create',
  EDIT_USER_JOURNEY_MILESTONE: '/userjourney/milestone/edit/:id',
  USER_JOURNEY_ADTASK: '/userjourney/adtasks',
  CREATE_USER_JOURNEY_ADTASK: '/userjourney/adtask/create',
  EDIT_USER_JOURNEY_ADTASK: '/userjourney/adtask/edit/:id',

  RECOMMENDED_ACTION: '/recommended-action',
  CREATE_RECOMMENDED_ACTION: '/recommended-action/create',
  EDIT_RECOMMENDED_ACTION: '/recommended-action/edit/:id',
  BREATHING_EXERCISES: '/breathing-exercise',
  CREATE_BREATHING_EXERCISES: '/breathing-exercise/create',
  EDIT_BREATHING_EXERCISES: '/breathing-exercise/edit/:id',
  // Advertisement space
  ADVERTISEMENT: '/advertisement',
  CREATE_ADVERTISEMENT: '/advertisement/create',
  EDIT_ADVERTISEMENT: '/advertisement/edit/:id',
  PREMIUMEXERCISES: '/premiumexercises',

  NAVAGITAIONS: '/navigation',
  CREATE_NAVIGATION: '/navigation/create',
  EDIT_NAVIGATION: '/navigation/edit/:id',
  VERSION_CONTROL: '/version_control',
  TIP_OF_THE_DAY: '/tip-of-the-day',
  CREATE_TIP_OF_THE_DAY: '/tip-of-the-day/create',
  EDIT_TIP_OF_THE_DAY: '/tip-of-the-day/edit',

  SERVICES: '/services-widget',
  CREATE_SERVICES: '/services/create-services',
  EDIT_SERVICES: '/services/edit/:id',

  //Coins

  BREATHFREE_COIN: '/coins',
  BREATHFREE_CREATE_COIN: '/coins/create-coins',
  BREATHFREE_EDIT_COIN: '/coins/create-coins/edit/:id',

  // CMS Vitals
  CMS_VITALS_PRODUCT_FAMILY: '/vitals/product-family',
  CMS_VITALS_CREATE_PRODUCT_FAMILY: '/vitals/create-product-family/create',
  CMS_VITALS_EDIT_PRODUCT_FAMILY: '/vitals/create-product-family/edit/:id',

  // CMS VITALS FAMILY PLANS
  CMS_VITALS_FAMILY_PLANS: '/vitals/family-plans',
  CMS_VITALS_CREATE_FAMILY_PLANS: '/vitals/create-family-plans/create',
  CMS_VITALS_EDIT_FAMILY_PLANS: '/vitals/create-family-plans/edit/:id',

  // CMS VITALS RECOMMENDATIONS
  CMS_VITALS_RECOMMENDATIONS: '/vitals/recommendations',
  CMS_VITALS_CREATE_RECOMMENDATIONS: '/vitals/create-recommendations/create',
  CMS_VITALS_EDIT_RECOMMENDATIONS: '/vitals/create-recommendations/edit/:id',

  // CMS VITALS AB TESTING
  CMS_VITALS_AB_TESTING: '/vitals/ab-testing',
  CMS_VITALS_CREATE_AB_TESTING: '/vitals/create-ab-testing/create',
  CMS_VITALS_EDIT_AB_TESTING: '/vitals/create-ab-testing/edit/:id',
  CMS_VITALS_UPLOAD_AB_TESTING: '/vitals/ab-testing/upload',

    // CMS PLAN ACTIVATION
    CMS_PLAN_ACTIVATION: '/plan-activation',
    // CMS_CREATE_PLAN_ACTIVATION: '/plan-activation/create',
    CMS_HISTORY_PLAN_ACTIVATION: '/plan-activation/history',

  //Coupon and Offers

  LATEST_COUPON_OFFER: '/coupons',
  LATEST_CREATE_COUPON_OFFER: '/coupons/create',
  LATEST_EDIT_COUPON_OFFER: '/coupons/edit/:id',

  //sprint 10
  // Dynamic widgets
  DYNAMIC_WIDGETS: '/dynamic-widgets',
  CREATE_WIDGETS: '/dynamic-widgets/create',
  EDIT_WIDGETS: '/dynamic-widgets/edit/:id',

  STATICS_WIDGETS: '/static-widgets',
  EDIT_STATIC_WIDGETS: '/static-widgets/edit/:id',
  HOME_PAGE_SECTIONS: '/home-page-sections',
  CREATE_HOME_PAGE_SECTIONS: '/home-page-sections/create',
  EDIT_HOME_PAGE_SECTIONS: '/home-page-sections/edit/:id',

  //Language master

  LOCALISATION_LANGUAGE_LIST: '/localisation-languages',

  //Referrals

  REFERRAL: '/referral',
  REWARDS: '/rewards',
  REFRRAL_HOME: '/referral-home',

  // RATING
  RATING: '/rating',
  RATING_CREATE: '/rating/create',
  RATING_EDIT: '/rating/edit/:id',

  // customer orders
  CUSTOMER_ORDERS: '/customer-orders',
  CREATE_CUSTOMER_ORDER: '/customer-orders/create',
  EDIT_CUSTOMER_ORDER: '/customer-orders/edit/:id',
  CUSTOMER_ORDER_DETAILS: '/customer-orders/details/:id',

  UPDATE_USER_REFILL: '/update-user-refill',
  AUDIT_LOGS: '/audit_logs',
  LCP_SESSION: '/lcp-session',

  //GAMIFICATION
  GAMIFICATIONNEW: '/gamification-new',
  GAMIFICATION_REWARDS: '/gamification-rewards',
  GAMIFICATION_EARN_COINS: '/gamification-earn-coins',
  GAMIFICATION_CREATE_COINS: '/create-coins',
  GAMIFICATION_CREATE_STREAKS: '/create-streaks',
  GAMIFICATION_EARN_COINS_EDIT: '/gamification-earn-coins/edit/:id',
  GAMIFICATION_STREAKS_EDIT: '/gamification-streaks/edit/:id',
  GAMIFICATION_ACTIVITY: '/gamification-activity',
  GAMIFICATION_CREATE_ACTIVTIY: '/create-gamification-activity',
  GAMIFICATION_ACTIVITY_EDIT: '/gamification-activity/edit/:id',

};

export const ROUTING_CONSTANTS = {
  FEEDS: '/feeds',
  FEED_DETAILS: 'feed-details',
  CHAT: '/chatList',
  STORAGE: '/storage',
};

export const TAB_NAME_CONSTANTS = {
  FEEDS: 'feeds',
  FEED_DETAILS: 'feed-details',
  CHAT: 'chatList',
  STORAGE: 'storage',
};

export const FILE_TYPES_CONSTANTS = {
  VIDEO: 'video',
  IMAGE: 'image',
  IMAGE_JPEG: 'image/jpeg',
  AUDIO: 'audio',
};

export const TOAST_TYPE_CONSTANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
};
export default TOAST_TYPE_CONSTANTS;

export const TEAM_TYPE_CONSTANTS = {
  PRIVATE: 'private',
  RESTRICTED: 'restricted',
  OPEN: 'open',
};

export const USER_ROLE_CONSTANTS = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const PROFILE_TYPE_CONSTANTS = {
  PROFILE: 'PROFILE',
};

export const LocalSessionKey = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  FINGERPRINT: 'fingerprint',
  FEED_ID: 'feedId',
  BREATEFREE_ID: 'breathefreeId',
  PERMISSSION_TAG: 'permissiontag',
  VERSION: 'ver',
};

export const EventType = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  PUBLISH_UNPUBLISH: 'PUBLISH_UNPUBLISH',
};

export const URLType = {
  EXTERNAL: 'EXTERNAL',
  EXTERNAL_PWA: 'EXTERNAL_PWA',
  INTERNAL: 'INTERNAL',
  INTERNAL_PWA: 'INTERNAL_PWA',
  YES: 'YES',
  NO: 'NO',
  MCQ: 'mcq',
  CHECKBOX: 'checkbox',
  QUIZ_CHECKBOX: 'option_box',
  FILL: 'fill',
  DATE_TIME: 'dob',
  attachmentFile: 'attachmentFile',
  webinar_record_link: 'webinar_record_link',
};

export const MasterDataType = {
  DOCTOR: 'DOCTOR',
  MEDICINE: 'MEDICINE',
};

export const ServiceType = {
  CONTENT: 'CONTENT',
  PATIENT_DAIRY: 'PATIENT_DAIRY',
};

export const Format = {
  DateTime: 'YYYY-MM-DD HH:mm:ss',
};

export const RegexValidations = {
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
};

export const LocalLangauageMasterKeys = {
  MASTERLANGAUGE: 'masterLanguage',
};
