import { API_CONSTANTS } from "app/constants/api-contants";
import * as dataAccess from "utils/ajax";
import Parsers from "utils/Parsers";
import Toast from "app/utils/Toast";
import ApiParser from "utils/ApiParser";

export const getGamificatinRewards = async ({ redemption_type } = {}) => {
  console.log(redemption_type);
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_GAMIFICATION_REWARD}?redemption_type=${redemption_type}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
export const createGamificationReward = async (incomingData) => {
  try {
    const payload = { data: incomingData };
    return await dataAccess.post(
      `${API_CONSTANTS.CREATE_GAMIFICATION_REWARD}`,
      payload
    );
  } catch (error) {
    console.error("Error in updateGamificatinActivityById:", error);
    throw error;
  }
};

export const getGamificatinRewardsById = async (id) => {
  const data = {reward_id : id}
  const payload ={
    data
  }
  let responseObj = await dataAccess.post(`${API_CONSTANTS.GET_GAMIFICATION_REWARD_BY_ID}`,payload);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    console.log(responseObj);

    return responseObj.data;
  } else {
    return [];
  }
};
export const deleteRewardByid = async (id) => {
  try {
    const data = {reward_id : id}
    const payload ={
      data
    }
    return await dataAccess.del(
      `${API_CONSTANTS.DELETE_REWARD_BY_ID}`,
      payload
    );
  } catch (error) {
    console.error("Error in Deleting Data By Id:", error);
    throw error;
  }
};
export const updateGamificationRewards = async (incomingData) => {
  try {
    const payload = { data: incomingData };
    return await dataAccess.put(
      `${API_CONSTANTS.UPDATE_REWARED_BY_ID}`,
      payload
    );
  } catch (error) {
    console.error("Error in Update Data :", error);
    throw error;
  }
};
export const getLocalasationRewardByid = async (id,lang) => {
  try {
    const data = {reward_id : id}
    const payload ={
      data
    }
    let responseObj = await dataAccess.post(`${API_CONSTANTS.GET_LOCALISATION_REWARD_BY_ID}?lang=${lang}`,payload);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      console.log(responseObj);
  
      return responseObj.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error in Update Data :", error);
    throw error;
  }
};
export const updateLocalisationGamificationRewards = async (incomingData,lang) => {
  try {
    const payload = { data: incomingData };
    return await dataAccess.put(
      `${API_CONSTANTS.UPDATE_LOCALISATION_REWARD}?lang=${lang}`,
      payload
    );
  } catch (error) {
    console.error("Error in Update Data :", error);
    throw error;
  }
};

export const getGamificationRewardToggle = async (id, status) => {
  try {
    const data = {
      reward_id: id,
      status: status // Already boolean from component
    };
    
    return await dataAccess.put(API_CONSTANTS.TOGGLE_GAMIFICATION_REWARD_ID, { data });
  } catch (error) {
    console.error('Error in updateGamificatinRewardById:', error);
    throw error;
  }
};