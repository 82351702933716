
import React from "react";
import BasePage from "../BasePage";
import CreateGamificationActivityPage from "app/container/CreateGamificationAcitvity";

const CreateGamificationActivity = () => {
  return (
    <BasePage>
      <CreateGamificationActivityPage />
    </BasePage>
  );
};

export default CreateGamificationActivity;
