const BaseURL = {
  BASE_URL: `${process.env.REACT_APP_AUTH_BASE_URL}/api/`,
  ARTICLES_BASE_URL: `${process.env.REACT_APP_ARTICLE_BASE_URL}/api/`,
  GAMIFICATION_BASE_URL: `${process.env.REACT_APP_GAMIFICATION_BASE_URL}/api/`,
  DOCTOR_BASE_URL: `${process.env.REACT_APP_DOCTOR_BASE_URL}/api/`,
  MEDICINE_BASE_URL: `${process.env.REACT_APP_COMMON_URL}/api/v1/medical-services/`,
  MEDICINE_BASE_URL_VERSION2: `${process.env.REACT_APP_COMMON_URL}/api/v2/medical-services/`,
  VITALS_BASE_URL: `${process.env.REACT_APP_COMMON_URL}/api/v1/vitals-tracking/`,
  VITALS_BASE_URL_VERSION2: `${process.env.REACT_APP_COMMON_URL}/api/v2/vitals-tracking/`,
  COMMON_BASE_URL: `${process.env.REACT_APP_COMMON_URL}/api/`,
  WIDGETS_BASE_URL: `${process.env.REACT_APP_ARTICLE_BASE_URL}/api/`,
  // LANGUAGES_BASE_URL: `${process.env.REACT_APP_COMMON_URL}/api/v4/`,
};

export const API_CONSTANTS = {
  AUTHENTICATE: `${BaseURL.BASE_URL}v1/authenticate`,
  LOGIN: `${BaseURL.BASE_URL}v1/cms/login`,
  RESEND_EMAIL: `${BaseURL.BASE_URL}v1/cms/resend/email`,
  LOGOUT: `${BaseURL.BASE_URL}v1/cms/logout`,
  REFRESH_TOKEN: `${BaseURL.BASE_URL}v1/refresh_token`,
  GET_ARTICLE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/article`,
  GET_BANNER_TAGS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/article/tags`,
  GET_SPLASH_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v1/cms/splash_screen`,
  UPDATE_ARTICLE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/article`,
  UPDATE_SPLASH_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/splash_screen`,
  ACTIVE_USER: `${BaseURL.BASE_URL}v1/cms/active/user`,

  // GET_HOMEPAGE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/home_page_order`,
  COMMON_HOMEPAGE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/landing_page_resources`,

  GET_HOMEPAGE_ORDER_HOME: `${BaseURL.ARTICLES_BASE_URL}v4/cms/homepage-section-ordering/HOME`,
  GET_HOMEPAGE_ORDER_EXPLORE: `${BaseURL.ARTICLES_BASE_URL}v4/cms/homepage-section-ordering/EXPLORE`,

  CREATE_HOME_PAGE_SECTION_ORDERING: `${BaseURL.ARTICLES_BASE_URL}v4/cms/homepage-section-ordering`,

  UPDATE_HOMEPAGE_ORDER_HOME: `${BaseURL.ARTICLES_BASE_URL}v4/cms/update-homepage-ordering/HOME`,
  UPDATE_HOMEPAGE_ORDER_EXPLORE: `${BaseURL.ARTICLES_BASE_URL}v4/cms/update-homepage-ordering/EXPLORE`,
  DELETE_HOME_PAGE_SECTION_ORDERING: `${BaseURL.ARTICLES_BASE_URL}v4/cms/homepage-section-ordering`,

  GET_ACTIVITY_VITALS: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/listing/activity_vitals`,
  POST_ACTIVITY_VITALS: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/update/activity_vital`,

  GET_CELEBRITY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/celebrity`,
  UPDATE_CELEBRITY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/update/celebrity`,
  DELETE_CELEBRITY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/celebrity`,

  GET_UNDERSTAND: `${BaseURL.ARTICLES_BASE_URL}v1/cms/understanding_devices`,
  UPDATE_UNDERSTAND: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/update/devices`,
  DELETE_UNDERSTAND: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/understanding_devices`,

  GET_DID_YOU_KNOW: `${BaseURL.ARTICLES_BASE_URL}v1/cms/did_you_know`,
  UPDATE_DID_YOU_KNOW: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/update/did_you_know`,
  DELETE_DID_YOU_KNOW: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/did_you_know`,

  GET_BREATHEFREE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/breathefree`,
  GET_HABITS: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/habits`,
  GET_HABITSTIP: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/habit/tips`,
  GET_HABITSTIP_DETAILS: `${BaseURL.GAMIFICATION_BASE_URL}v2/cms/detail/habit`,

  UPDATE_HABIT: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/update/habits`,
  UPDATE_HABIT_VERSION: `${BaseURL.GAMIFICATION_BASE_URL}v2/cms/update/habits/i18n`,
  UPDATE_HABITTIP: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/add_update_habit_tips`,
  UPDATE_HABITTIP_VERSION: `${BaseURL.GAMIFICATION_BASE_URL}v2/cms/update/habit-tips/i18n`,
  ADD_DRAG_DROP: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/update/habits/sorting_order`,

  UPDATE_BREATHEFREE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/update/breathefree`,
  DELETE_BREATHEFREE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/breathefree`,
  DELETE_SPLASHSCREEN: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/splash_screen`,

  //cms
  POST_CONTENT_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content`,

  GET_CONTENT_CMS_VERSION2: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/content`,

  SELECT_CONTENT_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content/home`,
  GET_TAGS_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/tags`,
  GET_COLLECTIONS_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/collection`,
  GET_COLLECTIONS_DETAILS_BY_ID: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/edit/collection`,
  GET_COLLECTIONS_DETAILS_BY_ID_VERSION: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/edit/collection`,

  CREATE_COLLECTION: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/collection`,
  CREATE_COLLECTION_VERSION: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/collection`,
  DELETE_COLLECTION: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/collection`,
  SEARCH_FILTER_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content/search`,
  DELETE_CMS_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/content`,
  UNPUBLISH_CMS_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content/unpublish`,
  PUBLISH_CMS_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content/publish`,
  UPLOAD_MEDIA: `${BaseURL.ARTICLES_BASE_URL}v1/cms/upload/media`,
  DELETE_MEDIA: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/media?filename=`,
  POST_SHORTS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/shorts`,

  POST_SHORTS_VERSION2: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/shorts`,
  POST_QUIZ: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/quiz`,
  POST_QUIZ_VERSION2: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/quiz`,
  POST_CMS_TAGS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/tags`,
  GET_COLLECTIONS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/collection`,
  GET_QUIZ_FK: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/content`,
  DELETE_TAG: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/tags`,
  POST_STORY_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/story`,
  GET_STORY_CMS: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/story`,
  UPDATE_STORY_CMS: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/story`,
  POST_GROUP_CMS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/group`,
  UPDATE_GROUP_CMS_VERSION: `${BaseURL.ARTICLES_BASE_URL}v2/cms/discover/group`,

  UNPUBLISH_CMS_TAG: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/tags/unpublish`,
  PUBLISH_CMS_TAG: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/tags/publish`,
  // DELETE_TAG: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/tags`,
  GET_STORIES: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/story`,
  PUBLISH_STORY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/story/publish`,
  UNPUBLISH_STORY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/story/unpublish`,
  DELETE_STORY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/story`,
  REORDER_STORY: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/story/reorder`,
  GET_GROUP: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/group`,
  DELETE_GROUP: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/group`,
  PUBLISH_GROUP: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/group/publish`,
  UNPUBLISH_GROUP: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/group/unpublish`,

  DELETE_DISCOVER_HOME_WIDGET: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/discover/home`,
  PUBLISH_DISCOVER_HOME_WIDGET: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/home/publish`,
  UNPUBLISH_DISCOVER_HOME_WIDGET: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/home/unpublish`,
  // GET_GROUP: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/group`,
  SHORTS_BULK_UPLOAD: `${BaseURL.ARTICLES_BASE_URL}v1/cms/bulk_upload/shorts`,
  CONTENT_BULK_UPLOAD: `${BaseURL.ARTICLES_BASE_URL}v1/cms/bulk_upload/content`,

  UPDATE_DISCOVER_GROUPS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/home`,
  SELECT_DISCOVER_GROUPS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/home`,
  GET_CATEGORY_CARD: `${BaseURL.ARTICLES_BASE_URL}v1/cms/discover/collection/ids_list/STORY`,
  GET_WEBINAR: `${BaseURL.ARTICLES_BASE_URL}v1/cms/get/webinar`,
  GET_WEBINAR_VERSION2: `${BaseURL.ARTICLES_BASE_URL}v2/cms/get/webinar`,

  UPDATE_WEBINAR: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/webinar`,
  UPDATE_WEBINAR_VERSION: `${BaseURL.ARTICLES_BASE_URL}v2/cms/add/webinar/i18n`,
  DELETE_WEBINAR: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/webinar`,
  DELETE_SPEAKER: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/speaker`,
  UPDATE_ROLES: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/webinar_speakers`,

  GET_CHALLENGES: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/challenges`,
  DELETE_GAMIFICATION: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/delete/challenges`,

  DELETE_ARTICLE: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/article`,
  ADD_MEDIA: `${BaseURL.ARTICLES_BASE_URL}v1/cms/article/media`,

  GET_MODULE: `${BaseURL.ARTICLES_BASE_URL}v2/cms/faq`,
  // GET_DATAIL_MODULE: `${BaseURL.ARTICLES_BASE_URL}v2/cms/detail/faq`,
  ADD_MODULE: `${BaseURL.ARTICLES_BASE_URL}v2/cms/add/faq`,
  UPDATE_MODULE: `${BaseURL.ARTICLES_BASE_URL}v2/cms/update/faq`,
  UPDATE_MODULE_STATUS: `${BaseURL.ARTICLES_BASE_URL}v2/cms/module/update-status`,
  DELETE_MODULE: `${BaseURL.ARTICLES_BASE_URL}v2/cms/delete/faq`,
  DELETE_FAQ: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/faq`,

  GET_STATIC_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/static`,

  STATIC_CONTENT_VERSION3: `${BaseURL.ARTICLES_BASE_URL}v3/cms/static`,

  ADD_STATIC_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/static`,

  DELETE_STATIC_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/static`,

  GET_USERS: `${BaseURL.BASE_URL}v1/cms/user`,
  GET_BANNERS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/banner`,
  GET_EVENT_BANNERS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/banner/events`,
  UPDATE_BANNER_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/banner`,

  GET_ACTIVITIES: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/activity`,

  GET_ARTICLE_DETAIL: `${BaseURL.ARTICLES_BASE_URL}v1/feeds`,

  UPDATE_USER: `${BaseURL.BASE_URL}v1/cms/add/user`,
  DELETE_USER: `${BaseURL.BASE_URL}v1/cms/delete/user`,
  UPDATE_PASSWORD: `${BaseURL.BASE_URL}v1/cms/update/password`,
  RESET_PASSWORD: `${BaseURL.BASE_URL}v1/cms/forgot/password`,
  GET_QUIZ: `${BaseURL.ARTICLES_BASE_URL}v1/get/cms/quiz`,
  ADD_QUIZ: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/quiz`,
  UPDATE_QUIZ: `${BaseURL.ARTICLES_BASE_URL}v1/cms/update/quiz`,
  DELETE_QUIZ: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/article`,

  GET_MEDICINE_MASTER_DATA: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/master/medicine`,
  GET_DOCTOR_MASTER_DATA: `${BaseURL.DOCTOR_BASE_URL}v1/cms/master/doctor`,

  GET_CONTENT_CHALLENGES: `${BaseURL.ARTICLES_BASE_URL}v1/cms/content/challenges`,
  GET_CONTENT_ACTIVITIES: `${BaseURL.ARTICLES_BASE_URL}v1/content/master`,

  UPDATE_ACTIVITIES: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/activity/update`,
  UPDATE_CONTENT_ACTIVITIES: `${BaseURL.ARTICLES_BASE_URL}v1/content/master/update`,

  UPDATE_GAMIFICATION: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/add/challenges`,
  UPDATE_CONTENT_GAMIFICATION: `${BaseURL.ARTICLES_BASE_URL}v1/cms/add/content/challenges`,

  DELETE_BANNER_CONTENT: `${BaseURL.ARTICLES_BASE_URL}v1/cms/delete/banners`,

  GET_QUIZ_DETAIL: `${BaseURL.ARTICLES_BASE_URL}v1/cms_quiz_detail`,

  GET_TERMS_OF_USE: `${BaseURL.ARTICLES_BASE_URL}v1/type_wise_toc?type=terms_of_use`,
  GET_PRIVACY_POLICY: `${BaseURL.ARTICLES_BASE_URL}v1/type_wise_toc?type=privacy_policy`,

  // user journey
  GET_USER_JOURNEY_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/task`,
  POST_USER_JOURNEY_TASK: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/task`,
  GET_USER_JOURNEY_TASK_DETAIL: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey`,
  GET_USER_LOCALASATION_TASK_DETAILS:`${BaseURL.GAMIFICATION_BASE_URL}v5/cms/user_journey/task`,
  GET_USER_JOURNEY_REWARD_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/reward`,
  POST_USER_JOURNEY_REWARD: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/reward`,
  GET_USER_JOURNEY_REWARD_DETAIL: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/reward`,
  GET_USER_JOURNEY_REWARD_DETAIL_VERSION: `${BaseURL.GAMIFICATION_BASE_URL}v5/cms/user_journey/reward`,

  GET_USER_JOURNEY_MILESTONE_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v4/cms/user_journey/reward`,
  POST_USER_JOURNEY_MILESTONE: `${BaseURL.GAMIFICATION_BASE_URL}v4/cms/user_journey/reward`,
  UPDATE_USER_JOURNEY_MILESTONE_STATUS: `${BaseURL.GAMIFICATION_BASE_URL}v4/cms/user_journey/reward_status`,

  GET_USER_JOURNEY_TASK_TYPE_LISTS: `${BaseURL.ARTICLES_BASE_URL}v4/cms/add-edit-ad-task`,
  CREATE_USER_JOURNEY_AD_TASK: `${BaseURL.ARTICLES_BASE_URL}v4/cms/ad-task`,
  UPDATE_USER_JOURNEY_AD_TASK_STATUS: `${BaseURL.ARTICLES_BASE_URL}v4/cms/update-ad-task-status`,

  DELETE_USER_JOURNEY_MILESTONE_DETAIL: `${BaseURL.GAMIFICATION_BASE_URL}v4/cms/user_journey/reward`,

  GET_USER_JOURNEY_COUPON_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/coupon`,
  POST_USER_JOURNEY_COUPON: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/coupon`,
  GET_USER_JOURNEY_COUPON_DETAIL: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/coupon`,

  GET_USER_JOURNEY_ADTASK_LIST: `${BaseURL.ARTICLES_BASE_URL}v4/cms/ad-task`,
  GET_USER_JOURNEY_ADTASK_DETAIL_VERSION: `${BaseURL.ARTICLES_BASE_URL}v5/cms/ad-task`,
  UPDATE_USER_JOURNEY_ADTASK_DETAIL_VERSION: `${BaseURL.ARTICLES_BASE_URL}v5/cms/ad-task/i18n`,
  // tip of the day

  GET_TIP_OF_THE_DAY_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/tip`,
  CREATE_TIP_OF_THE_DAY_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/tip`,
  UPDATE_TIP_OF_THE_DAY_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/tip`,
  DELETE_TIP_OF_THE_DAY_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/tip`,

  // exercises
  GET_EXERCISES_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/exercise`,
  POST_EXERCISES: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/exercise`,
  // ADVERTISEMENT
  GET_ADVERTISEMENT_LIST: `${BaseURL.WIDGETS_BASE_URL}v4/cms/breathing-exercise-ad`,
  CREATE_ADVERTISEMENT: `${BaseURL.WIDGETS_BASE_URL}v4/cms/breathing-exercise-ad`, 
  GET_ADVERTISEMENT_DETAILS_BY_ID: `${BaseURL.WIDGETS_BASE_URL}v4/cms/breathing-exercise-ad-by-id`,
  UPDATE_ADVERTISEMENT: `${BaseURL.WIDGETS_BASE_URL}v4/cms/breathing-exercise-ad`,
  UPDATE_STATUS_ADVERTISEMENT: `${BaseURL.WIDGETS_BASE_URL}v4/cms/update-ad-status`,
  DELETE_ADVERTISEMENT: `${BaseURL.WIDGETS_BASE_URL}v4/cms/breathing-exercise-ad`,

  //Recommended action
  GET_RECOMMENDED_LIST: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/story`,
  GET_RECOMMENDED_LIST_VERSION: `${BaseURL.GAMIFICATION_BASE_URL}v2/cms/user_journey/story`,

  POST_RECOMMENDED_STORY: `${BaseURL.GAMIFICATION_BASE_URL}v1/cms/user_journey/story`,
  UPDATE_RECOMMENDED_STORY: `${BaseURL.GAMIFICATION_BASE_URL}v2/cms/user_journey/story`,

  //Navigation
  GET_NAVIGATION_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/user_journey/redirection`,
  POST_NAVIGATION: `${BaseURL.ARTICLES_BASE_URL}v1/cms/user_journey/redirection`,

  GET_VERSION_CONTROL: `${BaseURL.BASE_URL}v1/cms/version/control`,
  UPDATE_VERSION_CONTROL: `${BaseURL.BASE_URL}v1/cms/version/control`,
  VERSION_CONTROL_V2: `${BaseURL.BASE_URL}v2/cms/version/control`,

  // LATEST API

  // SERVICES
  GET_SERVICES: `${BaseURL.ARTICLES_BASE_URL}v1/cms/services`,
  GET_SERVICES_VERSION2: `${BaseURL.ARTICLES_BASE_URL}v2/cms/services`,

  GET_SERVICES_ORDER: `${BaseURL.ARTICLES_BASE_URL}v1/cms/services_page_order`,
  POST_SERVICES_ORDER: `${BaseURL.ARTICLES_BASE_URL}v1/cms/services/display-order`,

  //Homepage Widgets
  GET_WIDGETS: `${BaseURL.ARTICLES_BASE_URL}v1/cms/widgets/breathfree-services`,

  // BANNER
  GET_LATEST_BANNERS: `${BaseURL.ARTICLES_BASE_URL}v2/cms/banners`,
  GET_LATEST_BANNERS_VERSION3: `${BaseURL.ARTICLES_BASE_URL}v3/cms/banners`,

  //CATEGORIES

  GET_LATEST_CATEGORIES: `${BaseURL.MEDICINE_BASE_URL}cms/widgets/categories`,
  GET_LATEST_CATEGORIES_VERSION2: `${BaseURL.MEDICINE_BASE_URL_VERSION2}cms/widgets/categories`,

  GET_MEDICINE_SEARCH: `${BaseURL.MEDICINE_BASE_URL}search`,

  //SubCategories
  GET_LATEST_SUBCATEGORIES: `${BaseURL.MEDICINE_BASE_URL}cms/widgets/sub-categories`,

  GET_LATEST_SUBCATEGORIES_PRODUCT_MAPPING: `${BaseURL.MEDICINE_BASE_URL}cms/widgets/sub-categories/product-mapping`,

  // merpay
  GET_MEDPAY_CATEGORIES: `${BaseURL.MEDICINE_BASE_URL}cms/categories`,

  GET_ALL_SEARCH_LATEST_SUBCATEGORIES: `${BaseURL.MEDICINE_BASE_URL}cms/widgets/categories`,

  SAVE_SEARCH_SCREEN: `${BaseURL.MEDICINE_BASE_URL}cms/widgets/categories/search`,

  // PRODUCT FAMILY

  GET_PRODUCT_FAMILY: `${BaseURL.VITALS_BASE_URL}cms/product-family`,
  GET_PRODUCT_FAMILY_: `${BaseURL.VITALS_BASE_URL}cms/product-family`,

  GET_PRODUCT_FAMILY_VERSION2: `${BaseURL.VITALS_BASE_URL_VERSION2}cms/product-family`,
  UPDATE_PRODUCT_FAMILY: `${BaseURL.VITALS_BASE_URL}cms/product-family`,
  POST_PRODUCT_FAMILY: `${BaseURL.VITALS_BASE_URL}cms/product-family`,
  GET_SERVICES_PRODUCT_FAMILY: `${BaseURL.VITALS_BASE_URL}cms/services`,

  // FAMILY PLANS
  GET_FAMILY_PLANS: `${BaseURL.VITALS_BASE_URL}cms/product-families/plans`,
  UPDATE_FAMILY_PLANS: `${BaseURL.VITALS_BASE_URL}cms/product-families/plans`,
  POST_FAMILY_PLANS: `${BaseURL.VITALS_BASE_URL}cms/product-families/plans`,
  GET_SERVICES_FAMILY_PLANS: `${BaseURL.VITALS_BASE_URL}cms/product-families-list`,

  // PLAN ACTIVATION
  GET_PLAN_ACTIVATION_HISTORY: `${BaseURL.VITALS_BASE_URL}cms/list-plan-activation`,
  CREATE_PLAN_ACTIVATION: `${BaseURL.VITALS_BASE_URL}cms/plan-activation`,

  // RECOMMENDATIONS
  GET_RECOMMENDATIONS: `${BaseURL.VITALS_BASE_URL}cms/recommendations`,
  GET_RECOMMENDATIONS_VERSION2: `${BaseURL.VITALS_BASE_URL_VERSION2}cms/recommendations`,
  POST_RECOMMENDATIONS: `${BaseURL.VITALS_BASE_URL}cms/recommendations`,
  UPDATE_RECOMMENDATIONS: `${BaseURL.VITALS_BASE_URL}cms/recommendations`,
  GET_VITALS: `${BaseURL.VITALS_BASE_URL}cms/vitals-list`,
  GET_AGE_RANGES: `${BaseURL.VITALS_BASE_URL}cms/age-ranges`,
  GET_VITAL_RANGES: `${BaseURL.VITALS_BASE_URL}cms/vital-ranges`,
  GET_RISK_LEVEL: `${BaseURL.VITALS_BASE_URL}cms/calculate-risk`,
  GET_COHORT: `${BaseURL.VITALS_BASE_URL}cms/cohort-list`,

  // AB Testing
  GET_AB_TESTING: `${BaseURL.VITALS_BASE_URL}cms/create-or-get-ab-testing`,
  POST_AB_TESTING: `${BaseURL.VITALS_BASE_URL}cms/create-or-get-ab-testing`,
  GET_AB_TESTING_ID: `${BaseURL.VITALS_BASE_URL}cms/get-ab-testing`,
  UPDATE_AB_TESTING: `${BaseURL.VITALS_BASE_URL}cms/update-ab-testing`,
  GET_PRODUCT_BASED_OPTIONS: `${BaseURL.VITALS_BASE_URL}cms/product-catelog-plans`,

  POST_AB_TESTING_UPLOAD_DATA: `${BaseURL.VITALS_BASE_URL}cms/bulk-activate-free-plan`,

  //BreathFree Coins

  BREATHEFREE_COINS: `${BaseURL.VITALS_BASE_URL}cms/product-family/coins`,
  CONVERSION_COINS: `${BaseURL.VITALS_BASE_URL}cms/global-coins-conversion`,

  // Product Coupon And Offer
  // MEDICINE_COUPON_OFFERS: `${BaseURL.MEDICINE_BASE_URL}cms/coupons`,

  // Product Coupon And Offer

  VITALS_COUPON_OFFERS: `${BaseURL.COMMON_BASE_URL}v1/cms/coupons`,
  COMMON_BASE_URL_COUPON: `${BaseURL.COMMON_BASE_URL}v1/cms/coupons`,

  COMMON_BASE_URL_COUPON_VERSION2: `${BaseURL.COMMON_BASE_URL}v2/cms/coupons`,

  // dynamic widgets
  GET_WIDGETS_LIST: `${BaseURL.WIDGETS_BASE_URL}v4/cms/widget`,
  GET_WIDGETS_TYPES: `${BaseURL.WIDGETS_BASE_URL}v4/cms/widget-type`,
  ADD_WIDGETS: `${BaseURL.WIDGETS_BASE_URL}v4/cms/add-widget`,
  UPDATE_WIDGETS: `${BaseURL.WIDGETS_BASE_URL}v4/cms/update-widget`,
  UPDATE_WIDGET_STATUS: `${BaseURL.WIDGETS_BASE_URL}v4/cms/update-widget-status`,
  DELETE_WIDGETS: `${BaseURL.WIDGETS_BASE_URL}v4/cms/widget`,
  DELETE_BANNER: `${BaseURL.WIDGETS_BASE_URL}v4/cms/delete-banner`,
  UPLOAD_WIDGET_IMAGE: `${BaseURL.WIDGETS_BASE_URL}v4/cms/upload-widget-image`,
  GET_BANNER_LOCALISATION_DETAILS: `${BaseURL.WIDGETS_BASE_URL}v5/cms/banner`,
  GET_WIDGET_LOCALISATION_DETAILS: `${BaseURL.WIDGETS_BASE_URL}v5/cms/dynamic_widget`,
  GET_LANDING_LIST: `${BaseURL.ARTICLES_BASE_URL}v1/cms/user-app-landing`,

  // landing page list screen
  GET_LANDING_PAGE_LIST_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v4/cms/landing-page`,
  CREATE_LANDING_PAGE_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v4/cms/landing-page`,
  UPDATE_LANDING_PAGE_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v4/cms/landing-page`,
  DELETE_LANDING_PAGE_SCREEN: `${BaseURL.ARTICLES_BASE_URL}v4/cms/landing-page`,
  DELETE_LANDING_PAGE_ORDER: `${BaseURL.ARTICLES_BASE_URL}v4/cms/landing-page-order`,

  UPDATE_BANNER_LOCALISATION_DETAILS: `${BaseURL.WIDGETS_BASE_URL}v5/cms/update-banner_details`,

  //static widget

  GET_STATIC_WIDGETS_LIST: `${BaseURL.WIDGETS_BASE_URL}v4/cms/static-widget`,
  GET_STATIC_WIDGETS_LIST_VERSION: `${BaseURL.WIDGETS_BASE_URL}v5/cms/static-widget`,
  UPDATE_STATIC_WIDGETS: `${BaseURL.WIDGETS_BASE_URL}v4/cms/update-static-widget`,
  UPDATE_STATIC_WIDGETS_VERSION: `${BaseURL.WIDGETS_BASE_URL}v5/cms/update-static-widget`,

  // LOCALISATION LANGUAGE MASTER`,
  LOCALISATION_lANGUAGE: `${BaseURL.COMMON_BASE_URL}v1/i18n/cms/languages`,
  // home page sections
  GET_HOME_PAGE_SECTION_LIST: `${BaseURL.WIDGETS_BASE_URL}v4/cms/homepage-section`,
  CREATE_HOME_PAGE_SECTION: `${BaseURL.WIDGETS_BASE_URL}v4/cms/homepage-section`,
  UPDATE_HOME_PAGE_SECTION: `${BaseURL.WIDGETS_BASE_URL}v4/cms/homepage-section`,
  GET_HOME_PAGE_SECTION_DETAILS: `${BaseURL.WIDGETS_BASE_URL}v5/cms/homepage-section`,

  //latest faq
  GET_DATAIL_MODULE: `${BaseURL.ARTICLES_BASE_URL}v3/cms/detail/faq`,
  UPDATE_MODULE_VERSION3: `${BaseURL.ARTICLES_BASE_URL}v3/cms/update/faq/i18n`,

  // Refferals
  GET_REFFERAL: `${BaseURL.COMMON_BASE_URL}v1/cms/referral/get`,
  ADD_REFFERAL: `${BaseURL.COMMON_BASE_URL}v1/cms/referral/add`,

  GET_REFFERE: `${BaseURL.COMMON_BASE_URL}v1/cms/referee/get`,
  ADD_REFFERE: `${BaseURL.COMMON_BASE_URL}v1/cms/referee/add`,

  REFFREL_IMAGE_UPLOAD: `${BaseURL.COMMON_BASE_URL}v1/cms/referral/upload_artwork_image`,

  //Success Stories

  SUCCESS_STORY: `${BaseURL.COMMON_BASE_URL}v1/cms/referral/success_story`,

  // Rating
  GET_RATING_NUDGE_LIST: `${BaseURL.COMMON_BASE_URL}v1/in-app-rating/cms/nudge-resend-config`,
  GET_RATING_LIST: `${BaseURL.COMMON_BASE_URL}v1/in-app-rating/cms/app-rating-config`,
  UPDATE_RATING_STATUS: `${BaseURL.COMMON_BASE_URL}v1/in-app-rating/cms/publish-app-rating-config`,
  GET_RATING_DROPDOWN_LIST: `${BaseURL.COMMON_BASE_URL}v1/in-app-rating/cms/app-rating-config/dropdown`,

  //Courses

  COURSES: `${BaseURL.COMMON_BASE_URL}v1/cms/course`,
  COURSES_CHAPTER_VIDEO: `${BaseURL.COMMON_BASE_URL}v1/cms/chapter`,
  COURSES_CHAPTER_QUIZ: `${BaseURL.COMMON_BASE_URL}v1/cms/quiz`,
  COURSES_COHORT: `${BaseURL.COMMON_BASE_URL}v1/course/cohort`,
  COURSES_LIST: `${BaseURL.COMMON_BASE_URL}v1/course/list`,
  COURSES_REORDER: `${BaseURL.COMMON_BASE_URL}v1/cms/courses/order`,
  COURSES_RECOMMENDED_GET: `${BaseURL.COMMON_BASE_URL}v1/course/cms/get_recommended_courses`,
  COURSES_QUIZ: `${BaseURL.COMMON_BASE_URL}v1/cms/quiz`,
  COURSES_DETAILS: `${BaseURL.COMMON_BASE_URL}v1/cms/course`,
  UPDATE_COURSES_STATUS: `${BaseURL.COMMON_BASE_URL}v1/cms/course/publish`,
  COURSES_TAG_LIST: `${BaseURL.COMMON_BASE_URL}v1/course/tags/list`,
  COURSES_START: `${BaseURL.COMMON_BASE_URL}v1/cms/start-course/list`,
  UPDATE_COURSES_START: `${BaseURL.COMMON_BASE_URL}v1/cms/start-course`,
  COURSE_CHAPTER_REORDER: `${BaseURL.COMMON_BASE_URL}v1/cms/chapter-wise-sequence`,

  // customer orders
  GET_DELIVERY_ADDRESS_LIST: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/delivery-address`,
  SET_ADDRESS: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/set_address`,
  ADD_NEW_ADDRESS: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/add/delivery-address`,
  MEDICINE_DETAILS: `${BaseURL.MEDICINE_BASE_URL}medicine-details`,
  MEDICINE_CHECK_OUT: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/medicine/checkout`,
  MEDICINE_DISCOUNT : `${BaseURL.COMMON_BASE_URL}v4/medical-services/cms/medicine/ad_reminder`,
  AD_REMINDER_FOR_MEDICINE : `${BaseURL.COMMON_BASE_URL}v4/medical-services/cms/medicine/ad_reminder_checks`,
  GET_PRESCRIPTION_LIST: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/prescription/list`,
  PRESCRIPTION_IS_FOR_ALL: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/prescription/is_for_all`,
  DELETE_PRESCRIPTION: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/prescription`,
  UOPLOAD_PRESCRIPTION: `${BaseURL.COMMON_BASE_URL}v1/cms/medical-services/cart/prescription`,

  UPDATE_USER_REFILL: `${BaseURL.COMMON_BASE_URL}v1/inhaler-services/cms/user-inhaler-refill`,

  //audit logs
  GET_AUDIT_LOGS:`${BaseURL.ARTICLES_BASE_URL}v1/cms/audit-log`,
  SEARCH_AUDIT_LOGS:`${BaseURL.ARTICLES_BASE_URL}v1/cms/search/audit-log`,

  //LCP
  GET_LCP_EDUCATOR_MAPPING : `${BaseURL.COMMON_BASE_URL}v1/lcp/sync_lcp_sheet`,
  //GAMIFICATION ACTIVITY
  GET_GAMIFICATION_ACTIVITES : `${BaseURL.COMMON_BASE_URL}v1/cms/coin-activities`,
  GET_GAMIFICATION_ACTIVITY_BY_ID : `${BaseURL.COMMON_BASE_URL}v1/cms/coin-activities`,
  UPDATE_GAMIFICATION_ACTIVITY : `${BaseURL.COMMON_BASE_URL}v1/cms/coin-activities`,
  TOGGLE_GAMIFICATION_ACTIVITY : `${BaseURL.COMMON_BASE_URL}v1/cms/coin-activities/toggle`,

  //GMIFICATION REWARD

  GET_GAMIFICATION_REWARD : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward`,
  CREATE_GAMIFICATION_REWARD : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward`,
  GET_GAMIFICATION_REWARD_BY_ID : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward-by-id`,
  DELETE_REWARD_BY_ID : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward-by-id`,
  UPDATE_REWARED_BY_ID : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward-by-id`,
  GET_LOCALISATION_REWARD_BY_ID : `${BaseURL.WIDGETS_BASE_URL}v4/cms/gamification-reward-by-id`,
  UPDATE_LOCALISATION_REWARD : `${BaseURL.WIDGETS_BASE_URL}v4/cms/reward-localisation-details`,
  TOGGLE_GAMIFICATION_REWARD_ID: `${BaseURL.WIDGETS_BASE_URL}v4/cms/update-reward-status`,

};
