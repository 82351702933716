import NewInput from "app/components/NewInput";
import Dropdown from "app/shared/components/Dropdown";
import React, { useState,useEffect } from "react";
import ValidationMessage from "app/utils/ValidationMessage";
import { imageSizeValidation } from "app/utils/Parsers";
import Toast from "app/utils/Toast";
import { uplaodMedia } from "app/api/widgets";
import Button from "app/components/button";
import { useHistory,useParams } from "react-router";
import { CloseBlackIcon } from "app/constants/image-constants";
import { getGamificatinActivityById, updateGamificatinActivityById } from "app/api/gamiticationactivities";
import NumberInput from "app/components/UI/NumberInput";
const CreateGamificationActivityPage = () => {
  const params = useParams();
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [state, setState] = useState({
    id: "",
    name: "",
    coins: "",
    frequency: "",
    daily_capping: "",
    coinValue: "",
    rewardDescription: "",
    redirectionLink: "",
    thumbnailImageHub: "",
    thumbnailImageModal: "",
    icon: "",
  });
  const history = useHistory();
  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const cancelMedia = async (e, file) => {
    setState({ ...state, [e.target.name]: "" });
  };
  useEffect(() => {
    if (params?.id) {
   getGamificationActivityDetails(params?.id)
    }
  }, [])
  const getGamificationActivityDetails = async (id) => {
    setPageLoading(true);
    const response = await getGamificatinActivityById(id);
    if (response?.options?.frequency) {
      // Transform the frequency options array into the format needed for the dropdown
      const mappedOptions = response.options.frequency.map(([value, label]) => ({
        value: value,
        label: label
      }));
      setFrequencyOptions(mappedOptions);
    }
    setActivityData(response?.activity);
    if (response && response.activity) {
      setState({
        ...state,
        id: response.activity?.id, 
        name: response.activity?.name,
        coins: response.activity?.coins,
        frequency: response.activity?.frequency,
        daily_capping: response.activity?.daily_capping,
        icon: response.activity?.icon || "",
        
      });
      let icon = response.activity?.icon
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.activity?.icon}`
        : null;
      setThumbnailImage(icon);
    }
    setPageLoading(false);
  };
  const handleMediaUpload = async (e, index) => {
    if (!e) return;
    let file;
    let reader = new FileReader();
    if (e?.target?.name === "icon") {
      file = e.target.files[0];
      if (
        (file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png") &&
        imageSizeValidation(e)
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia("image", file);

        if (e.target.name === "icon") {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setThumbnailImage(reader.result);
          };
          reader.readAsDataURL(file);
        }

        Toast.success(file?.name + " uploaded");
      }
    }
  };
  const handleSubmit = async () => {
    try {
      const payload = {
        id: state.id,
        name: state.name,
        coins: Number(state.coins),
        frequency: state.frequency,
        daily_capping: Number(state.daily_capping),
        icon: state.icon,
      };

      
      if (!payload.id) {
        Toast.error('Activity ID is required');
        return;
      }

      const responseObj = await updateGamificatinActivityById(payload.id, payload);
      if (responseObj?.response?.code === 200) {
        Toast.success(responseObj?.data?.message);
        history.goBack();
      } else {
        Toast.error(responseObj?.data?.message);
      }
    } catch (error) {
      console.error('Error updating activity:', error);
      Toast.error('Error updating activity');
    }
  };
  return (
    <>
      <div>
        <h3>Add Activity</h3>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <NewInput
            label="Activity"
            name="activity"
            //   options={DropdownOptions.ChallengeOptions}
            placeholder="Activity"
            value={state.name}
            handleChange={handleTextChange}
            disabled
          />
        </div>
        <div className="col-sm-4">
          <NewInput
            label="Coins"
            id="coins"
            type="text"
            // placeholder="Enter Title"
            name="coins"
            value={state.coins}
            onChange={handleTextChange}
            // errorText={error.Title}
          />
        </div>
        <div className="col-sm-4">
          <Dropdown
            label="Frequency"
            name="frequency"
            options={frequencyOptions}
            placeholder="Frequency"
            value={state.frequency}
            handleChange={handleTextChange}
          />
        </div>
        <div className="col-sm-4">
          <NumberInput
            label="Capping"
            id="daily_capping"
            // type="text"
            placeholder="Capping"
            name="daily_capping"
            value={state.daily_capping}
            onChange={handleTextChange}
          />
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="mt-4">
              {state.icon && (
                <p
                  className="cancel"
                  onClick={(e) => {
                    if (state.icon) {
                      cancelMedia(e, state.icon);
                    }
                    setThumbnailImage(null);
                    setState({ ...state, icon: "" });
                  }}
                >
                  Cancel Image
                </p>
              )}
            </div>
            <div className="upload-buttons ">
              <input
                type="file"
                id="thumbnailFile"
                style={{ display: "none" }}
                name="icon"
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />
              <div className="upload-label">
                <label className="mb-2">Activity Coin </label>
                <Button
                  onClick={() => {
                    const image = document.getElementById("thumbnailFile");
                    image.click();
                  }}
                  isDisabled={state.icon}
                  type="button"
                  name={state.icon != "" ? "Uploaded" : "Upload Image"}
                />
              </div>
              {thumbnailImage && (
                <div className="attachment_img_wrapper">
                  <img
                    src={thumbnailImage}
                    alt="sample"
                    className="feed__attachment"
                  />
                  <div className="attachment_img_wrapper_close">
                    <img
                      src={CloseBlackIcon}
                      alt="Remove"
                      onClick={(e) => {
                        if (state.icon) {
                          cancelMedia(e, state.icon);
                        }
                        setThumbnailImage(null);
                        setState({ ...state, icon: "" });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="userFormArea">
          <Button
            // name={params.id ? 'Update' : 'Save'}
            name="Save"
            onClick={handleSubmit}
          />
          <label
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </label>
        </div>
      </div>
    </>
  );
};

export default CreateGamificationActivityPage;
