const TableHeaderConstant = {
  Title: 'Title',
  Description: 'Description',
  PublishedBy: 'Published by',
  FirstPublishedTime: 'First published time',
  FirstPublishedBy: 'First published By',
  LastEdited: 'Last Edited',
  LastUpdatedTime: 'Last updated time',
  LastUpdatedBy: 'Last updated by',
  Status: 'Status',
  ExpiredOn: 'Expired On',
  Tag: 'Tag',
  TypeOfBanner: 'Type of Banner',
  TotalPoints: 'Total Points',
  Frequency: 'Frequency',
  ProfilePhoto: 'Profile Photo',
  EmployeeID: 'Emp. ID',
  Name: 'Name',
  FirstName: 'First Name',
  LastName: 'Last Name',
  EmailID: 'Email ID',
  MobileNumber: 'M. No',
  Role: 'Role',
  MasterDataName: 'Master data name',
  URL: 'Master Sheet URL',
  Question: 'Question',
  Action: 'Action',
  ActivityName: 'Activity Name',
  BonusPoints: 'Bonus Points',
  SequenceOrder: 'SequenceOrder',
  TitleVisiblity: 'Title visiblity',
  Visiblity: 'Visiblity',
  Type: 'Type',
  ActivityId: 'Activity Id',
  AllotedPoint: 'Alloted Point',
  ServiceType: 'Service Type',
  Drag: 'Drag',
  CreatedBy: 'Created By',
  UpdatedBy: 'Updated By',
  CreatedOn: 'Created On',
  StartDate: 'Start Date',
  HabitId: 'HabitId',
  Share: 'Share',
  ArticleId: 'Article Id',
  QuizId: 'Quiz Id',
  WebinarId: 'Webinar Id',
  ordering: 'Ordering',
  tags: 'Tags',
  publishedDate: 'Published Date',
  updateddDate: 'Updated Date',
  contentId: 'Content ID',
  contentType: 'Content Type',
  streakDay: 'Streak Day',
  exerciseId: 'Exercise ID',
  exerciseType: 'Exercise Type',
  exerciseLevel: 'Exercise Level',
  contentTitle: 'Content Title',
  streakMilestone: 'Streak Milestone',
  rewardType: 'Reward Type',
  couponCodecoin: 'Coupon Code/Coin',
  // couponCodecoin:'Coupon Code/Coin',
  redeemLink: 'Redeem Link',
  order: 'Order',
  expiresOn: 'Expires On',
  redirectType: 'Redirect Type',
  redirectTo: 'Redirect To',
  actionTitle: 'Action Title',
  exercisesName: 'Exercise Name',
  ID: 'ID',
  deepLink: 'Deep Link',
  asthmaOrder: 'Asthma Order',
  copdOrder: 'COPD Order',
  ildOrder: 'ILD Order',
  arOrder: 'AR Order',
  otherOrder: 'Other Order',
  editOrder: 'Edit Order',
  navigationPoint: 'Navigation Point',
  urlType: 'Url Type',
  url: 'URL',
  taskType: 'Task Type',
  couponId: 'Coupon ID',
  rewardId: 'Reward ID',
  couponCode: 'Coupon Code',
  shared: 'Shared',
  storyName: 'Story Name',
  storyType: 'Story Type',
  updatedDate: 'Updated Date',
  collectionID: 'Collection ID',
  sno: 'S.No',
  tip: 'Tip',
  date: 'Date',
  Ranking: 'Ranking',
  OffOn: 'Off/On',
  medicineName: 'Medicine Name',
  medicineId: 'Medicine Id',
  discount: 'Discount(%)',
  diagnosticName: 'Diagnostic Name',
  diagnosticId: 'Diagnostic Id',
  widgetName: 'Widget Name',
  widgetId: 'Widget ID',
  mappedToWhichSection: 'Mapped To Which Section',
  bannerName: 'Banner Name',
  bannerId: 'Banner ID',
  ageCohort: 'Age Cohorts',
  ModuleNumber: 'No',
  ModuleName: 'Module',
  sectionName: 'Section Name',
  sectionID: 'Section ID',
  widgetMapped: 'Widget Mapped',
  adType: 'Ad Type',
  taskId: 'Task ID',
  streakDays: 'Streak Days',
  cohortTagAdded: 'Cohort Tags Added',
  testimonialName:'Testimonial Name',
  testimonialType:'Testimonial Type',
  testimonialId:"Testimonial Id",
  taskname: 'Task Name',
  milestoneName: 'Milestone Name',
  milestoneStreakDays: 'Milestone Streak Day',
  milestoneId: 'Milestone ID',
  streakType: 'Streak Type',
  eventName: 'Event Name',
  android: 'Android',
  ios: 'iOS',
  partnerOrderId:'Partner Order ID',
  customerName:'Customer Name',
  orderStatus: 'Order Status',
  itemName: 'Item Name', 
  packFrom:'Pack From',
  packQty:'Pack Qty',
  qty:'Qty',
  price:'Price',
  totalAmount:'Total Amt',
  mobileNoORCSVFile: 'Mobile No. OR CSV file',
  planSelected: 'Plan Selected', 
  activationDate: 'Activation Date',
  VersionNumber: 'Version Number',
  criteria:'Criteria',
  pageName: 'Page Name',
  visibility: 'Visibility',
  lastEditedBy: 'Last edited by',
  exercisename: 'Exercise Name',
  typeofexcercise: 'Type of Exercise',
  premium: 'Premium(yes/no)',
  methodtype:'Method Type',
  userid:'User Id',
  ViewData:'View Data'
};

// export const NewsFeedTableHeader = [
//     TableHeaderConstant.Title,
//     TableHeaderConstant.Description,
//     TableHeaderConstant.PublishedBy,
//     TableHeaderConstant.LastEdited,
//     TableHeaderConstant.Status,
//     TableHeaderConstant.ExpiredOn,
//     TableHeaderConstant.Tag
// ]

export const WebinarHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  // {
  //   Header: TableHeaderConstant.Description,
  //   accessor: 'description',
  // },
  {
    Header: TableHeaderConstant.WebinarId,
    accessor: 'webinar_id',
  },
  // webinar_id
  {
    Header: TableHeaderConstant.UpdatedBy,
    accessor: 'updated_by',
  },
  {
    Header: TableHeaderConstant.CreatedOn,
    accessor: 'created_on',
  },
  {
    Header: TableHeaderConstant.StartDate,
    accessor: 'start_date',
  },
  // {
  //   Header: TableHeaderConstant.ExpiredOn,
  //   accessor: 'expired_on',
  // },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Share,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const NewsFeedTableHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.Description,
    accessor: 'description',
  },
  {
    Header: TableHeaderConstant.FirstPublishedTime,
    accessor: 'first_published_time',
  },
  {
    Header: TableHeaderConstant.FirstPublishedBy,
    accessor: 'first_published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.ArticleId,
    accessor: 'article_id',
  },
  {
    Header: TableHeaderConstant.QuizId,
    accessor: 'quiz_id',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'expired_on',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const HabitHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Name,
    accessor: 'habit_name',
  },

  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.HabitId,
    accessor: 'habit_id',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const SplashScreenHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.Description,
    accessor: 'description',
  },
  {
    Header: TableHeaderConstant.FirstPublishedTime,
    accessor: 'first_published_time',
  },
  {
    Header: TableHeaderConstant.FirstPublishedBy,
    accessor: 'first_published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

// export const BannersTableHeader = [
//   TableHeaderConstant.Title,
//   TableHeaderConstant.TypeOfBanner,
//   TableHeaderConstant.PublishedBy,
//   TableHeaderConstant.LastEdited,
//   TableHeaderConstant.Status,
//   TableHeaderConstant.ExpiredOn,
//   TableHeaderConstant.Tag,
// ];

export const BannersTableHeader = [
  {
    Header: TableHeaderConstant.TypeOfBanner,
    accessor: 'type_of_banner',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'expired_on',
  },
  // {
  //   Header: TableHeaderConstant.Tag,
  //   accessor: 'tag',
  // },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const CmsContentTableHeader = [
  {
    Header: 'Title',
    accessor: 'type_of_banner',
  },
  {
    Header: 'Tags',
    accessor: 'published_by',
  },
  {
    Header: 'Published Date',
    accessor: 'last_edited',
  },
  {
    Header: 'Updated Date',
    accessor: 'status',
  },
  {
    Header: 'Content ID',
    accessor: 'expired_on',
  },
  {
    Header: 'Content Type',
    accessor: 'tag',
  },
  {
    Header: 'Status',
    accessor: '',
  },
  {
    Header: 'Expired On',
    accessor: '',
  },
  {
    Header: 'Action (Edit, Delete, Unpublished)',
    accessor: '',
  },
];

// export const GamificationTableHeader = [
//   TableHeaderConstant.Title,
//   TableHeaderConstant.TotalPoints,
//   TableHeaderConstant.PublishedBy,
//   TableHeaderConstant.LastEdited,
//   TableHeaderConstant.Frequency,
// ];
export const GamificationTableHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.TotalPoints,
    accessor: 'total_point',
  },
  // {
  //   Header: TableHeaderConstant.BonusPoints,
  //   accessor: 'bonus_points',
  // },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Frequency,
    accessor: 'alloted_days',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

// export const UsersTableHeader = [
//     TableHeaderConstant.ProfilePhoto,
//     TableHeaderConstant.EmployeeID,
//     TableHeaderConstant.Name,
//     TableHeaderConstant.EmailID,
//     TableHeaderConstant.MobileNumber,
//     TableHeaderConstant.Status,
//     TableHeaderConstant.Role
// ]

export const UsersTableHeader = [
  // {
  //   Header: TableHeaderConstant.ProfilePhoto,
  //   accessor: 'title',
  // },
  {
    Header: TableHeaderConstant.EmployeeID,
    accessor: 'employee_id',
  },
  {
    Header: TableHeaderConstant.FirstName,
    accessor: 'first_name',
  },
  {
    Header: TableHeaderConstant.LastName,
    accessor: 'last_name',
  },
  {
    Header: TableHeaderConstant.EmailID,
    accessor: 'email',
  },
  {
    Header: TableHeaderConstant.MobileNumber,
    accessor: 'mobile',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Role,
    accessor: 'role',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const BreatheFreeHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.SequenceOrder,
    accessor: 'sorting_order',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const HomePageHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  // {
  //   Header: TableHeaderConstant.TitleVisiblity,
  //   accessor: 'title_visibility',
  // },

  // {
  //   Header: TableHeaderConstant.SequenceOrder,
  //   accessor: 'sorting_order',
  // },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const SubCategoriesProductListHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.TitleVisiblity,
    accessor: 'title_visibility',
  },

  // {
  //   Header: TableHeaderConstant.SequenceOrder,
  //   accessor: 'sorting_order',
  // },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const MedicineOrderHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.VersionNumber,
    accessor: 'android_version',
  },
  {
    Header: TableHeaderConstant.criteria,
    accessor: 'criteria',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const DiagonosticOrderHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.VersionNumber,
    accessor: 'android_version',
  },
  {
    Header: TableHeaderConstant.criteria,
    accessor: 'criteria',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited_time',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const CollectionHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.tags,
    accessor: 'tags',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'created_on',
  },
  {
    Header: TableHeaderConstant.updateddDate,
    accessor: 'updated_on',
  },
  {
    Header: TableHeaderConstant.contentId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.contentType,
    accessor: 'content_type',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'end_date',
  },
];

export const ActivityandVitalsHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Name,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.SequenceOrder,
    accessor: 'sorting_order',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'last_updated_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.Visiblity,
    accessor: 'visibility',
  },

  // {
  //   Header: TableHeaderConstant.Status,
  //   accessor: 'status',
  // },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const CelebrityHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.SequenceOrder,
    accessor: 'sorting_order',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const UnderStandingHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.SequenceOrder,
    accessor: 'sorting_order',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const DidYouKnowHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'last_updated_time',
  },
  {
    Header: TableHeaderConstant.SequenceOrder,
    accessor: 'sorting_order',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },

  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

// export const MastersTableHeader = [
//   TableHeaderConstant.MasterDataName,
//   TableHeaderConstant.URL,
//   TableHeaderConstant.PublishedBy,
//   TableHeaderConstant.LastEdited,
// ];

/* {
    Header: TableHeaderConstant.URL,
    accessor: 'url',
  }, */
export const MastersTableHeader = [
  {
    Header: TableHeaderConstant.MasterDataName,
    accessor: 'master_data_name',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

// export const StaticPageTableHeader = [
//     TableHeaderConstant.Title,
//     TableHeaderConstant.PublishedBy,
//     TableHeaderConstant.LastEdited
// ]

export const StaticPageTableHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const FAQTableHeader = [
  {
    // Header: TableHeaderConstant.ModuleNumber,
    Header: TableHeaderConstant.ModuleNumber,
    accessor: 'index_number',
  },
  {
    Header: TableHeaderConstant.ModuleName,
    accessor: 'module_name',
  },

  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },

  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'updated_on',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const ActivityTableHeader = [
  {
    Header: TableHeaderConstant.ActivityName,
    accessor: 'activity_name',
  },
  {
    Header: TableHeaderConstant.TotalPoints,
    accessor: 'alloted_points',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const QuizTableHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'quiz_title',
  },
  {
    Header: TableHeaderConstant.PublishedBy,
    accessor: 'published_by',
  },
  {
    Header: TableHeaderConstant.LastEdited,
    accessor: 'last_edited',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const UserJourneyHeader = [
  {
    Header: TableHeaderConstant.streakDay,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.exerciseId,
    accessor: 'exerciseId',
  },
  {
    Header: TableHeaderConstant.contentTitle,
    accessor: 'contentTitle',
  },
  {
    Header: TableHeaderConstant.taskType,
    accessor: 'task_name',
  },
  {
    Header: TableHeaderConstant.exerciseType,
    accessor: 'exerciseType',
  },
  {
    Header: TableHeaderConstant.exerciseLevel,
    accessor: 'exerciseLevel',
  },
  {
    Header: TableHeaderConstant.contentId,
    accessor: 'contentId',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const StreakRewardHeader = [
  {
    Header: TableHeaderConstant.streakMilestone,
    accessor: 'streak_milestone',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.rewardType,
    accessor: 'rewardType',
  },
  {
    Header: TableHeaderConstant.couponCodecoin,
    accessor: 'couponCodecoin',
  },
  {
    Header: TableHeaderConstant.redeemLink,
    accessor: 'redeemLink',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const CouponHeader = [
  {
    Header: TableHeaderConstant.couponId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.rewardId,
    accessor: 'fk_reward_id',
  },
  {
    Header: TableHeaderConstant.couponCode,
    accessor: 'coupon_code',
  },
  {
    Header: TableHeaderConstant.shared,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const RecommendedActionHeader = [
  {
    Header: TableHeaderConstant.order,
    accessor: 'order',
  },
  {
    Header: TableHeaderConstant.expiresOn,
    accessor: 'expiresOn',
  },
  {
    Header: TableHeaderConstant.redirectType,
    accessor: 'redirectType',
  },
  {
    Header: TableHeaderConstant.redirectTo,
    accessor: 'redirectTo',
  },
  // {
  //   Header: TableHeaderConstant.actionTitle,
  //   accessor: 'actionTitle',
  // },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.asthmaOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.copdOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.ildOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.arOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.otherOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];

export const ExercisesHeader = [
  {
    Header: TableHeaderConstant.exercisesName,
    accessor: 'exercisesName',
  },
  {
    Header: TableHeaderConstant.ID,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'publishedDate',
  },
  // {
  //   Header: TableHeaderConstant.deepLink,
  //   accessor: 'deepLink',
  // },
  {
    Header: TableHeaderConstant.Type,
    accessor: 'type',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.asthmaOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.copdOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.ildOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.arOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.otherOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];
export const NavigationHeader = [
  {
    Header: TableHeaderConstant.Title,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.navigationPoint,
    accessor: 'navigation_point',
  },
  {
    Header: TableHeaderConstant.urlType,
    accessor: 'url_type',
  },
  {
    Header: TableHeaderConstant.url,
    accessor: 'url',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];
export const StoriesHeader = [
  {
    Header: TableHeaderConstant.Drag,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.storyName,
    accessor: 'story_title',
  },
  {
    Header: TableHeaderConstant.storyType,
    accessor: 'story_type',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'published_date',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updated_on',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.collectionID,
    accessor: 'collection_id',
  },
];

export const ServicesWidgetsTableHeader = [
  {
    Header: TableHeaderConstant.Ranking,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Title,
    accessor: 'service_name',
  },
  {
    Header: TableHeaderConstant.LastUpdatedTime,
    accessor: 'updated_on',
  },

  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: 'updated_by',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const MedicineListHeader = [
  {
    Header: TableHeaderConstant.Ranking,
    accessor: 'ranking',
  },
  {
    Header: TableHeaderConstant.medicineName,
    accessor: 'medicinename',
  },
  {
    Header: TableHeaderConstant.medicineId,
    accessor: 'medicineId',
  },
  {
    Header: TableHeaderConstant.discount,
    accessor: 'discount',
  },
  {
    Header: TableHeaderConstant.UpdatedBy,
    accessor: 'updatedby',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];
export const LabTestListHeader = [
  {
    Header: TableHeaderConstant.Ranking,
    accessor: 'ranking',
  },
  {
    Header: TableHeaderConstant.diagnosticName,
    accessor: 'medicinename',
  },
  {
    Header: TableHeaderConstant.diagnosticId,
    accessor: 'medicineId',
  },
  {
    Header: TableHeaderConstant.discount,
    accessor: 'discount',
  },
  {
    Header: TableHeaderConstant.UpdatedBy,
    accessor: 'updatedby',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];

export const TipOfTheDayHeader = [
  {
    Header: TableHeaderConstant.sno,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.tip,
    accessor: 'tip_text',
  },
  {
    Header: TableHeaderConstant.date,
    accessor: 'tip_date',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const ProductFamilyTableHeader = [
  {
    Header: 'Family Id',
    accessor: 'id',
  },
  {
    Header: 'Family Name',
    accessor: 'family_name',
  },
  {
    Header: 'Internal Family Name',
    accessor: 'internal_family_name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Services',
    accessor: 'service_name',
  },
  {
    Header: 'Published Date',
    accessor: 'created_on',
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_on',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Components',
    accessor: '',
  },
];

export const FamilyPlansTableHeader = [
  {
    Header: 'Plan Id',
    accessor: 'id',
  },
  {
    Header: 'Family Name',
    accessor: 'family_name',
  },
  {
    Header: 'Plan Name',
    accessor: 'plan_name',
  },
  {
    Header: 'Internal Plan Name',
    accessor: 'internal_plan_name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Published Date',
    accessor: 'created_on',
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_on',
  },
  {
    Header: 'Razorpay Id',
    accessor: 'razorpay_id',
  },
  {
    Header: 'Paid',
    accessor: 'paid',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Components',
    accessor: '',
  },
];

export const RecommendationsTableHeader = [
  {
    Header: 'Recommendation Id',
    accessor: 'id',
  },
  {
    Header: 'Recommendation Name',
    accessor: 'internal_name',
  },
  {
    Header: 'Vital',
    accessor: 'vital',
  },
  {
    Header: 'Risk Level',
    accessor: 'risk_level',
  },
  {
    Header: 'Cohort',
    accessor: 'cohort',
  },
  {
    Header: 'No. of content added',
    accessor: 'no_of_content_added',
  },
  {
    Header: 'Published Date',
    accessor: 'created_on',
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_on',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Components',
    accessor: '',
  },
];

export const RecommendContentTableHeader = [
  {
    Header: 'Select',
    accessor: 'select',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Tags',
    accessor: 'combinedTags',
  },
  {
    Header: 'Published Date',
    accessor: 'created_on',
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_on',
  },
  {
    Header: 'Content ID',
    accessor: 'content_id',
  },
  {
    Header: 'Content Type',
    accessor: 'content_type ',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Expired On',
    accessor: 'expired_on',
  },
];

export const ContentOrderingTableHeader = [
  {
    Header: 'Drag',
    accessor: 'drag',
  },
  {
    Header: 'Choose language',
    accessor: 'language',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const CoursesOrderingTableHeader = [
  {
    Header: 'Drag',
    accessor: 'drag',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Last Edited',
    accessor: 'last_edited_time',
  },
  {
    Header: 'Last Updated by',
    accessor: 'last_updated_by',
  },
];
export const CoursesChapterTableHeader = [
  {
    Header: 'Drag',
    accessor: 'drag',
  },
  {
    Header: 'Seq.',
    accessor: 'Seq',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
  },
  {
    Header: 'Chapter type',
    accessor: 'chapter_type',
  },
  {
    Header: 'Last Edited',
    accessor: 'last_edited',
  },
  {
    Header: 'Last Updated by',
    accessor: 'last_updated_by',
  },
  {
    Header: 'Control',
    accessor: 'control',
  },
];
export const CoursesRecommendedTableHeader = [
  {
    Header: 'Drag',
    accessor: 'drag',
  },
  {
    Header: 'Courses title',
    accessor: 'language',
  },
];

export const DynamicWidgetBannerHeader = [
  {
    Header: TableHeaderConstant.bannerName,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.bannerId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.ageCohort,
    accessor: 'agecohort',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updatedDate',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'expiredon',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.asthmaOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.copdOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.ildOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.arOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.otherOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];

export const DynamicWidgetTestimonialHeader = [
  {
    Header: TableHeaderConstant.testimonialName,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.testimonialId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.testimonialType,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.ageCohort,
    accessor: 'agecohort',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updatedDate',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'expiredon',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.asthmaOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.copdOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.ildOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.arOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.otherOrder,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.editOrder,
    accessor: '',
  },
];

export const DynamicWidgetHeader = [
  {
    Header: TableHeaderConstant.widgetName,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.Type,
    accessor: 'type',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'publishedDate',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updatedDate',
  },
  {
    Header: TableHeaderConstant.widgetId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.mappedToWhichSection,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.ExpiredOn,
    accessor: 'expiredon',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const StaticWidgetHeader = [
  {
    Header: TableHeaderConstant.widgetName,
    accessor: 'name',
  },
  // {
  //   Header: TableHeaderConstant.Type,
  //   accessor: 'type',
  // },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'publishedDate',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updatedDate',
  },
  {
    Header: TableHeaderConstant.widgetId,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.mappedToWhichSection,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const HomePageSectionHeader = [
  {
    Header: TableHeaderConstant.sectionName,
    accessor: 'name',
  },
  // {
  //   Header: TableHeaderConstant.Type,
  //   accessor: 'type',
  // },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'publishedDate',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updatedDate',
  },
  {
    Header: TableHeaderConstant.sectionID,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.widgetMapped,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const AbTestingTableHeader = [
  {
    Header: 'A/B Test',
    accessor: 'id',
  },
  {
    Header: 'Module',
    accessor: 'module',
  },
  {
    Header: 'Variants',
    accessor: 'variants',
  },
  {
    Header: 'Users',
    accessor: 'users',
  },
  {
    Header: 'Filters',
    accessor: 'filters',
  },
  {
    Header: 'Published Date',
    accessor: 'created_on',
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_on',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Components',
    accessor: '',
  },
];

export const AdTaskListHeader = [
  {
    Header: TableHeaderConstant.taskname,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.cohortTagAdded,
    accessor: 'module',
  },
  {
    Header: TableHeaderConstant.streakDays,
    accessor: 'streak_days',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'published_date',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updated_date',
  },
  {
    Header: TableHeaderConstant.taskId,
    accessor: 'task_id',
  },
  {
    Header: TableHeaderConstant.adType,
    accessor: 'add_type',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  // {
  //   Header: TableHeaderConstant.ExpiredOn,
  //   accessor: '',
  // },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const MilestoneListHeader = [
  {
    Header: TableHeaderConstant.milestoneName,
    accessor: 'name',
  },
  {
    Header: TableHeaderConstant.cohortTagAdded,
    accessor: 'module',
  },
  {
    Header: TableHeaderConstant.milestoneStreakDays,
    accessor: 'streak_days',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'published_date',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updated_date',
  },
  {
    Header: TableHeaderConstant.milestoneId,
    accessor: 'task_id',
  },
  {
    Header: TableHeaderConstant.streakType,
    accessor: 'add_type',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  // {
  //   Header: TableHeaderConstant.ExpiredOn,
  //   accessor: '',
  // },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const RatingListHeader = [
  {
    Header: TableHeaderConstant.sno,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.ModuleName,
    accessor: 'module_name',
  },
  {
    Header: TableHeaderConstant.eventName,
    accessor: 'event_name',
  },
  {
    Header: TableHeaderConstant.android,
    accessor: 'android',
  },
  {
    Header: TableHeaderConstant.ios,
    accessor: 'ios',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'created_on',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: 'updated_date',
  },
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
];

export const CourseListHeader = [
  {
    Header: 'Course Title',
    accessor: 'title',
  },
  {
    Header: 'Chapters',
    accessor: 'chapter_type',
  },
  {
    Header: 'Cohort',
    accessor: 'cohort_type',
  },
  {
    Header: 'Age',
    accessor: 'age',
  },
  {
    Header: 'Frontend Tags (Categories)',
    accessor: 'frontend_tags',
  },
  {
    Header: 'Live',
    accessor: 'live_status',
  },
  {
    Header: 'Edit',
    accessor: 'edit',
  },
];

export const CustomerOrderHeader = [
  {
    Header: TableHeaderConstant.partnerOrderId,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.customerName,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.orderStatus,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },

]
export const CustomerOrderMedicineHeader = [
  {
    Header: TableHeaderConstant.itemName,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.packFrom,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.packQty,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.qty,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.price,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.totalAmount,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
]
export const MedicineDiscount =[
  {
    Header: 'Medicine Name',
    accessor: 'medicineName',
  },
  {
    Header: 'Medicine Id',
    accessor: 'medicineId',
  },
  {
    Header: 'Discount',
    accessor: 'discount',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Edit Discount',
    accessor: 'editDiscount',
  },
]

export const MedicineReminder =[
  {
    Header: 'Communication Type',
    accessor: 'commtype',
  },
  {
    Header: 'Toggle',
    accessor: 'toggle',
  }
  
]
export const AdvertisementHeader = [
  {
    Header: TableHeaderConstant.Name,
    accessor: 'advertisement_name',
  },
  {
    Header: TableHeaderConstant.ID,
    accessor: 'id',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: 'publishedDate',
  }, 
  {
    Header: TableHeaderConstant.Status,
    accessor: 'status',
  }, 
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  // {
  //   Header: TableHeaderConstant.asthmaOrder,
  //   accessor: '',
  // },
  // {
  //   Header: TableHeaderConstant.copdOrder,
  //   accessor: '',
  // },
  // {
  //   Header: TableHeaderConstant.ildOrder,
  //   accessor: '',
  // },
  // {
  //   Header: TableHeaderConstant.arOrder,
  //   accessor: '',
  // },
  // {
  //   Header: TableHeaderConstant.otherOrder,
  //   accessor: '',
  // },
  // {
  //   Header: TableHeaderConstant.editOrder,
  //   accessor: '',
  // },
];
export const PremiumExerciseHeader = [
  {
    Header: TableHeaderConstant.exercisename,
    accessor: 'exercise_name',
  },
  {
    Header: TableHeaderConstant.typeofexcercise,
    accessor: 'type_of_exercise',
  },
  {
    Header: TableHeaderConstant.premium,
    accessor: 'premium',
  }, 
  
  
];
export const PlanActivationHistoryHeader = [
  {  
    Header: TableHeaderConstant.mobileNoORCSVFile,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.planSelected,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.publishedDate,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.activationDate,
    accessor: '',
  },
  
  
];
export const UserLandingHeader = [
  {
    Header: "ID",
    accessor: 'id',
  },
  {
    Header: "Cohort",
    accessor: 'cohort',
  },
  {
    Header: 'Landing Page',
    accessor: 'landing_page',
  },
  // {
  //   Header: 'Start Date',
  //   accessor: 'start_date',
  // },
  // {
  //   Header: 'End Date',
  //   accessor: 'end_date',
  // },
  {
    Header: 'Start Time',
    accessor: 'start_time',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
  },
  {
    Header: 'Toggle',
    accessor: 'toggle',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  
];
export const GamificationCoinsHeader =[
  {
    Header: 'Reward Title',
    accessor: 'rewardTitle',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Offer Type',
    accessor: 'offertype',
  },
  {
    Header: 'Coin Value',
    accessor: 'coinvalue',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
export const GamificationStreaksHeader =[
  {
    Header: 'Reward Title',
    accessor: 'rewardTitle',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Offer Type',
    accessor: 'offertype',
  },
  {
    Header: 'Streak name',
    accessor: 'coinvalue',
  },
  {
    Header: 'Milestone',
    accessor: 'milestone',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
export const GamificationActivityHeader =[
  {
    Header: 'Activity Name',
    accessor: 'activityname',
  },
  {
    Header: 'Coins',
    accessor: 'coins',
  },
  {
    Header: 'Frequency',
    accessor: 'frequency',
  },
  {
    Header: 'Daily Capping',
    accessor: 'dailycapping',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
export const LandingScreenHeader = [
  {
    Header: TableHeaderConstant.ID,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.pageName,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.visibility,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.lastEditedBy,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.LastUpdatedBy,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.deepLink,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.Action,
    accessor: '',
  },
  
];
export const AuditLogsTableHeader = [
  {
    Header: TableHeaderConstant.sno,
    accessor: 'title',
  },
  {
    Header: TableHeaderConstant.methodtype,
    accessor: 'method_type',
  },
  {
    Header: TableHeaderConstant.ModuleName,
    accessor: 'module_name',
  },
  {
    Header: TableHeaderConstant.ViewData,
    accessor: 'view_data',
  },
  {
    Header: TableHeaderConstant.CreatedOn,
    accessor: 'created_on',
  },
  {
    Header: TableHeaderConstant.UpdatedBy,
    accessor: 'updated_by',
  },
  
];

export const RefillHistoryHeader = [
  {  
    Header: TableHeaderConstant.mobileNoORCSVFile,
    accessor: '',
  },
  {
    Header: TableHeaderConstant.updatedDate,
    accessor: '',
  },
];

export default NewsFeedTableHeader;
