import { APP_CONSTANTS } from 'constants/app-constants';
import * as RiIcons from 'react-icons/ri';
/* {
    title: 'Webinars',
    id: 'webinars',
    to: '/webinars',
    active: false,
  }, */
/* {
    title: 'Quiz',
    id: 'quiz',
    to: APP_CONSTANTS.QUIZ,
    active: false,
  }, */
// comment change
const SideMenuData = [
  {
    title: 'Services',
    id: 'Services',
    to: APP_CONSTANTS.SERVICES,
    active: false,
    key: 'SERVICES',
  },
  {
    title: 'Coupons & Offers',
    id: 'LatestCouponAndOffer',
    to: APP_CONSTANTS.LATEST_COUPON_OFFER,
    active: false,
    key: 'LATESTCOUPONANDOFFERS',
  },
  {
    title: 'Feed',
    id: 'feed',
    to: APP_CONSTANTS.NEWSFEED,
    active: false,
    key: 'FEED',
  },
  {
    title: 'Banner',
    id: 'banner',
    to: APP_CONSTANTS.BANNER_LIST,
    active: false,
    key: 'BANNER',
  },
  {
    title: 'Homepage Widgets',
    id: 'widget',
    to: APP_CONSTANTS.WIDGET_LIST,
    active: false,
    key: 'HOMEPAGEWIDGET',
  },
  {
    title: 'Challenges',
    id: 'challenges',
    to: APP_CONSTANTS.GAMIFICATION,
    active: false,
    key: 'CHALLANGES',
  },
  {
    title: 'Activities',
    id: 'activities',
    to: APP_CONSTANTS.ACTIVITIES,
    active: false,
    key: 'ACTIVITIES',
  },
  {
    title: 'Upload Master Details',
    id: 'uploadmaster',
    to: APP_CONSTANTS.UPLOAD_MASTER_DATA,
    active: false,
    key: 'UPLOAD_MASTER_DETAILS',
  },
  {
    title: 'Static Content',
    id: 'staticcontent',
    to: APP_CONSTANTS.STATIC_PAGE,
    active: false,
    key: 'STATIC_CONTENT',
  },
  {
    title: 'FAQ',
    id: 'faq',
    to: APP_CONSTANTS.FAQ,
    active: false,
    key: 'FAQ',
  },
  {
    title: 'CMS Users',
    id: 'cmsusers',
    to: APP_CONSTANTS.USERS,
    active: false,
    key: 'CMS_USERS',
  },

  {
    title: 'Splash Screen',
    id: 'onBoarding',
    to: APP_CONSTANTS.ONBOARDING,
    active: false,
    key: 'ONBOARDING',
  },
  {
    title: 'HomePage Ordering',
    id: 'homePageOrdering',
    to: APP_CONSTANTS.HOMEPAGEORDER,
    active: false,
    key: 'HOMEPAGEORDER',
  },

  {
    title: 'Vitals and Activity',
    id: 'activityVitals',
    to: APP_CONSTANTS.ACTIVITY_VITALS,
    active: false,
    key: 'ACTIVITY_VITALS',
  },
  {
    title: 'Discover Content',
    id: 'discoverContent',
    active: false,
    key: 'DISCOVER_CONTENT',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Content',
        id: 'content',
        to: APP_CONSTANTS.CMS_PANEL,
        active: false,
        key: 'CONTENT',
      },
      {
        title: 'Collections',
        id: 'collections',
        to: APP_CONSTANTS.CMS_PANEL_COLLECTIONS,
        active: false,
        key: 'COLLECTIONS',
      },
      {
        title: 'Tags and Categories',
        id: 'tagsandcategories',
        to: APP_CONSTANTS.CMS_PANEL_TAGS,
        active: false,
        key: 'TAGSANDCATEGORIES',
      },
      {
        title: 'Discover Page',
        id: 'discoverPage',
        to: APP_CONSTANTS.CMS_PANEL_DISCOVER,
        active: false,
        key: 'DISCOVERPAGE',
      },
      {
        title: 'Discover Home Widget',
        id: 'discoverhomewidget',
        to: APP_CONSTANTS.CMS_PANEL_DISCOVER_HOME_WIDGET,
        active: false,
        key: 'DISCOVERHOMEWIDGET',
      },
    ],
  },
  {
    title: 'Medicine Delivery',
    id: 'medicinedelivery',
    active: false,
    key: 'MEDICINE_DELIVERY',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Medicine Order',
        id: 'medicineorder',
        to: APP_CONSTANTS.MEDICINEPAGEORDER,
        active: false,
        key: 'MEDICINEORDER',
      },
      {
        title: 'Small Banner',
        id: 'medicinesmallbanner',
        to: APP_CONSTANTS.MEDICINESMALLBANNER,
        active: false,
        key: 'MEDICINESMALLBANNER',
      },
      {
        title: 'Large Banner',
        id: 'medicinelargebanner',
        to: APP_CONSTANTS.MEDICINELARGEBANNER,
        active: false,
        key: 'MEDICINELARGEBANNER',
      },
      {
        title: 'Medicine Categories',
        id: 'medicinecategories',
        to: APP_CONSTANTS.MEDICINECATEGORIES,
        active: false,
        key: 'MEDICINECATEGORIES',
      },
      {
        title: 'Medicine Search',
        id: 'medicinesearch',
        to: APP_CONSTANTS.MEDICINESEARCHSCREEN,
        active: false,
        key: 'MEDICINESEARCHSCREEN',
      },
      {
        title: 'Medicine Reminder',
        id: 'medicineReminder',
        to: APP_CONSTANTS.MEDICINESREMINDERSCREEN,
        active: false,
        key: 'MEDICINESREMINDERSCREEN',
      },
      {
        title: 'Medicine Discount',
        id: 'medicineDiscount',
        to: APP_CONSTANTS.MEDICINESDISCOUNT,
        active: false,
        key: 'MEDICINESDISCOUNT',
      },
    ],
  },
  {
    title: 'Diagonostic',
    id: 'diagonostic',
    active: false,
    key: 'DIAGNOSTIC',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Diagonostic Order',
        id: 'diagonosticorder',
        to: APP_CONSTANTS.DIAGONOSTICPAGEORDER,
        active: false,
        key: 'DIAGONOSTICPAGEORDER',
      },
      {
        title: 'Small Banner',
        id: 'diagonosticsmallbanner',
        to: APP_CONSTANTS.DIAGONOSTICSMALLBANNER,
        active: false,
        key: 'DIAGONOSTICSMALLBANNER',
      },
      {
        title: 'Large Banner',
        id: 'diagonosticlargebanner',
        to: APP_CONSTANTS.DIAGONOSTICLARGEBANNER,
        active: false,
        key: 'DIAGONOSTICLARGEBANNER',
      },
      {
        title: 'Diagonostic Categories',
        id: 'diagonosticcategories',
        to: APP_CONSTANTS.DIAGONOSTICCATEGORIES,
        active: false,
        key: 'DIAGONOSTICCATEGORIES',
      },
      {
        title: 'Diagnostic Search',
        id: 'diagonosticsearch',
        to: APP_CONSTANTS.DIAGONOSTICSEARCHSCREEN,
        active: false,
        key: 'DIAGONOSTICSEARCHSCREEN',
      },
    ],
  },
  // {
  //   title: 'Breathing Exercises',
  //   id: 'exercises',
  //   to: APP_CONSTANTS.BREATHING_EXERCISES,
  //   active: false,
  //   key: 'EXERCISES',
  // },
  {
    title: 'Breathing Exercises',
    id: 'exercises',
    active: false,
    key: 'EXERCISES',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Breathing Exercise',
        id: 'exercises',
        to: APP_CONSTANTS.BREATHING_EXERCISES,
        active: false,
        key: 'EXERCISES',
      },
      {
        title: 'Advertisement space',
        id: 'advertisementspace',
        to: APP_CONSTANTS.ADVERTISEMENT,
        active: false,
        key: 'EXERCISES',
      },
      {
        title: 'Premium exercises',
        id: 'premiumexercises',
        to: APP_CONSTANTS.PREMIUMEXERCISES,
        active: false,
        key: 'EXERCISES',
      },
      
    ],
  },
  {
    title: 'User Journey',
    id: 'userjourney',
    active: false,
    key: 'USER_JOURNEY',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Mandatory Tasks',
        id: 'userjourney',
        to: APP_CONSTANTS.USER_JOURNEY,
        active: false,
        key: 'USER_JOURNEY',
      },
      {
        title: 'Streak Rewards',
        id: 'userjourney',
        to: APP_CONSTANTS.USER_JOURNEY_STREAK_REWARDS,
        active: false,
        key: 'USER_JOURNEY',
      },
      {
        title: 'Coupons',
        id: 'userjourney',
        to: APP_CONSTANTS.USER_JOURNEY_COUPON,
        active: false,
        key: 'USER_JOURNEY',
      },
      {
        title: 'Navigations',
        id: 'navigation',
        to: APP_CONSTANTS.NAVAGITAIONS,
        active: false,
        key: 'USER_JOURNEY',
      },
      {
        title: 'Milestone',
        id: 'milestone',
        to: APP_CONSTANTS.USER_JOURNEY_MILESTONE,
        active: false,
        key: 'USER_JOURNEY',
      },
      {
        title: 'Ad Task',
        id: 'adtask',
        to: APP_CONSTANTS.USER_JOURNEY_ADTASK,
        active: false,
        key: 'USER_JOURNEY',
      },
    ],
  },
  {
    title: 'Landing pages',
    id: 'home',
    active: false,
    key: 'HOME',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Dynamic Widgets',
        id: 'dynamicwidgets',
        to: APP_CONSTANTS.DYNAMIC_WIDGETS,
        active: false,
        key: 'DYNAMIC_WIDGETS',
      },
      {
        title: 'Static Widgets',
        id: 'staticwidgets',
        to: APP_CONSTANTS.STATICS_WIDGETS,
        active: false,
        key: 'STATIC_WIDGETS',
      },
      {
        title: 'Home Page Sections',
        id: 'homepagesections',
        to: APP_CONSTANTS.HOME_PAGE_SECTIONS,
        active: false,
        key: 'HOME_PAGE_SECTIONS',
      },
      {
        title: 'Home Page Ordering',
        id: 'homePageOrdering',
        to: APP_CONSTANTS.HOMEPAGEORDERING,
        active: false,
        key: 'HOMEPAGEORDER',
      },
      // {
      //   title: 'User Landing',
      //   id: 'userLanding',
      //   to: APP_CONSTANTS.USERLANDING,
      //   active: false,
      //   key: 'USERLANDING',
      // },
      {
        title: 'Landing Listing Screen',
        id: 'LandingPagesListingScreen',
        to: APP_CONSTANTS.LANDING_PAGE,
        active: false,
        key: 'LANDINGPAGES',
      },

    ],
  },
  {
    title: 'Tip Of The Day',
    id: 'TIP_OF_THE_DAY',
    to: APP_CONSTANTS.TIP_OF_THE_DAY,
    active: false,
    key: 'USER_JOURNEY',
  },
  {
    title: 'Recommended Action',
    id: 'recommendedAction',
    to: APP_CONSTANTS.RECOMMENDED_ACTION,
    active: false,
    key: 'RECOMMENDED_ACTIONS',
  },
  {
    title: 'Webinar',
    id: 'webinar',
    to: APP_CONSTANTS.WEBINAR,
    active: false,
    key: 'WEBINAR',
  },
  {
    title: 'Habits',
    id: 'habits',
    to: APP_CONSTANTS.HABITS,
    active: false,
    key: 'HABITS',
  },
  {
    title: 'Breathfree Coins',
    id: 'Coins',
    to: APP_CONSTANTS.BREATHFREE_COIN,
    active: false,
    key: 'COINS',
  },

  {
    title: 'Master Language List',
    id: 'localisation',
    active: false,
    key: 'LOCALISATION',
    to: APP_CONSTANTS.LOCALISATION_LANGUAGE_LIST,
  },
  {
    title: 'Video',
    id: 'video',
    active: false,
    key: 'VIDEO',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Breathefree Video',
        id: 'breathefree',
        to: APP_CONSTANTS.BREATEFREE,
        active: false,
        key: 'BREATEFREE',
      },
      {
        title: 'Celebrity Videos',
        id: 'celebrity',
        to: APP_CONSTANTS.CELEBRITY,
        active: false,
        key: 'CELEBRITY',
      },
      {
        title: 'Did You Know ?',
        id: 'didyouknow',
        to: APP_CONSTANTS.DIDYOUKNOW,
        active: false,
        key: 'DIDYOUKNOW',
      },
      {
        title: 'Devices Understanding videos',
        id: 'understandingdevices',
        to: APP_CONSTANTS.UNDERSTANDING_DEVICES,
        active: false,
        key: 'UNDERSTANDINGDEVICES',
      },
    ],
  },
  {
    title: 'In App Update',
    id: 'INAPPUPDATE',
    to: APP_CONSTANTS.VERSION_CONTROL,
    active: false,
    key: 'INAPPUPDATE',
  },
  {
    title: 'Product Catalog',
    id: 'productCatalog',
    active: false,
    key: 'PRODUCT_CATALOG',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Product Family',
        id: 'productFamily',
        to: APP_CONSTANTS.CMS_VITALS_PRODUCT_FAMILY,
        active: false,
        key: 'PRODUCT FAMILY',
      },
      {
        title: 'Family Plans',
        id: 'familyPlans',
        to: APP_CONSTANTS.CMS_VITALS_FAMILY_PLANS,
        active: false,
        key: 'Family Plans',
      },
      {
        title: 'Recommendations',
        id: 'recommendations',
        to: APP_CONSTANTS.CMS_VITALS_RECOMMENDATIONS,
        active: false,
        key: 'Recommendations',
      },
      {
        title: 'Plan Activation',
        id: 'planactivation',
        to: APP_CONSTANTS.CMS_PLAN_ACTIVATION,
        active: false,
        key: 'Plan Activation',
      },
    ],
  },
  {
    title: 'AB Testing',
    id: 'abTesting',
    active: false,
    key: 'AB_TESTING',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'AB Testing',
        id: 'abTesting',
        to: APP_CONSTANTS.CMS_VITALS_AB_TESTING,
        active: false,
        key: 'AB Testing',
      },
    ],
  },
  {
    title: 'Referral',
    id: 'referral',
    active: false,
    key: 'REFERRAL',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Rewards & Referrals',
        id: 'rewards',
        to: APP_CONSTANTS.REWARDS,
        active: false,
        key: 'Rewards',
      },
      {
        title: 'Referral home',
        id: 'referralHome',
        to: APP_CONSTANTS.REFRRAL_HOME,
        active: false,
        key: 'referral home',
      },
    ],
  },
  {
    title: 'Ratings',
    id: 'rating',
    to: APP_CONSTANTS.RATING,
    active: false,
    key: 'RATINGS',
  },
  {
    title: 'Courses',
    id: 'courses',
    to: APP_CONSTANTS.COURSES,
    active: false,
    key: 'CMS_COURSES',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Recommend Courses',
        id: 'recommendCourses',
        to: APP_CONSTANTS.COURSES_RECOMMEND,
        active: false,
        key: 'COURSESRECOMMEND',
      },
      {
        title: 'Courses Page Ordering',
        id: 'coursesOrdering',
        to: APP_CONSTANTS.COURSES_PAGE_ORDERING,
        active: false,
        key: 'COURSESPAGEORDERING',
      },
      {
        title: 'Courses Banners',
        id: 'coursesBanners',
        to: APP_CONSTANTS.COURSES_BANNERS,
        active: false,
        key: 'COURSESBANNERS',
      },
      {
        title: 'Course Creation',
        id: 'courseCreation',
        to: APP_CONSTANTS.COURSES_CREATION,
        active: false,
        key: 'COURSESCREATION',
      },
      {
        title: 'ELS Ads Space',
        id: 'elsAdsSpace',
        to: APP_CONSTANTS.ELS_ADS_SPACE,
        active: false,
        key: 'ELSADSSPACE',
      },
    ],
   
  },
  {
    title: 'Update User Refill',
    id: 'updateUserRefill',
    to: APP_CONSTANTS.UPDATE_USER_REFILL,
    active: false,
    key: 'UPDATE_USER_REFILL',
  },
  {
    title: 'Customer Orders',
    id: 'customerOrders',
    to: APP_CONSTANTS.CUSTOMER_ORDERS,
    active: false,
    key: 'CUSTOMER_ORDERS',
  },
  {
    title: 'LCP Session',
    id: 'lcpsession',
    active: false,
    key: 'LCP_SESSION',
    to: APP_CONSTANTS.LCP_SESSION},
    {
    title: 'Gamification',
    id: 'gamificationnew',
    active: false,
    key: 'GAMIFICATIONNEW',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Rewards (Coins/Milestone)',
        id: 'rewardscoins',
        to: APP_CONSTANTS.GAMIFICATION_REWARDS,
        active: false,
        key: 'RewardsCoins',
      },
      {
        title: 'Earn Coins (Activity <> Coin)',
        id: 'earncoins',
        to: APP_CONSTANTS.GAMIFICATION_ACTIVITY,
        active: false,
        key: 'earn coins',
      },
    ],
  },
  {
    title: 'Audit Logs',
    id: 'auditlogs',
    active: false,
    key: 'AUDIT_LOGS',
    to: APP_CONSTANTS.AUDIT_LOGS,
  },
];

export default SideMenuData;
